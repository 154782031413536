import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { BiShowAlt } from "react-icons/bi";
import { RiEdit2Line, RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from "../../../../utilities/hooks/auth-hook";
import Loader from "../../../../assets/Loader";
export default function SharerequestTable() {
  const { useState } = React;
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const SharerequestTable = useQuery(
    "SharerequestTable ",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}get-request-share`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  function DropMenu({ rowData }) {
    return (
      <Menu>
        <MenuButton
          rounded={"sm"}
          size={"xs"}
          as={IconButton}
          aria-label="Options"
          icon={
            <IconButton
              size={"xs"}
              rounded={"sm"}
              icon={<BsThreeDots />}
              variant={"outline"}
              colorScheme={"blackAlpha"}
            />
          }
          variant="outline"
        />
        <MenuList>
          <MenuItem
            icon={<BiShowAlt />}
            onClick={() =>
              navigate("/payments/Createpayments", {
                state: {
                  state: rowData,
                  isReadOnly: true,
                  isEdit: false,
                  isShow: true,
                },
              })
            }
          >
            Show
          </MenuItem>
          {/* <MenuItem
            icon={<RiEdit2Line />}
            onClick={() =>
              navigate("/payments/Createpayments", {
                state: {
                  state: rowData,
                  isReadOnly: false,
                  isEdit: true,
                  isShow: false,
                },
              })
            }
          >
            Edit
          </MenuItem> */}
        </MenuList>
      </Menu>
    );
  }
  const [columns, setColumns] = useState([
    { title: "Name", field: "stakeholder.name" },
    {
      title: "Total Capital ",
      field: "stakeholder.current_capital",
    },

    {
      title: "Requested Share",
      field: "share_amount",
    },
    {
      title: "Alloted",
      field: "alloted_share",
    },
    {
      title: "Status",
      field: "status",
    },

    {
      title: "Approved Share Request",
      field: "allowed_share_amount",
    },

    // {
    //   title: "Action",
    //   render: (rowData) => {
    //     return (
    //       <Button
    //         onClick={() =>
    //           navigate("/Share_request/Create_Share_request", {
    //             state: {
    //               state: rowData,
    //               isReadOnly: true,
    //               isEdit: false,
    //               isShow: true,
    //             },
    //           })
    //         }
    //         bg={"#B76A1D"}
    //         color={"white"}
    //       >
    //         Detail
    //       </Button>
    //     );
    //   },
    // },
  ]);

  return (
    <Box w={"100%"}>
      {SharerequestTable.isFetching ? (
        <Loader />
      ) : (
        <MaterialTable
          title="Share Request List"
          columns={columns}
          data={data}
          options={{
            filtering: true,
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#E82481",
              color: "#FFF",
            },
          }}
        />
      )}
    </Box>
  );
}
