import {
  Box,
  Button,
  Center,
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Wrap,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import * as Yup from "yup";
import CustomInputField from "../../utilities/components/CustomInputField";
import { useAuth } from "../../utilities/hooks/auth-hook";
const CreatePlowBack = ({ isOpen, onClose, editId, model }) => {
  const { token, logout } = useAuth();

  const toast = useToast();
  const queryclient = useQueryClient();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const shareMutation = useMutation(
    async (newData) =>
      await axios.post(process.env.REACT_APP_BACKEND_URL + "shares", newData, {
        headers,
      }),
    {
      retry: false,
    }
  );

  const initialValues = {
    name: "",
    minimum_attendant: "",
    description: "",
    start_from_minimum_attendant: false,
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .max(
        editId?.stakeholder?.current_capital,
        `Amount should be less than ${editId?.stakeholder?.current_capital}`
      ),
  });
  // console.log({ model });

  const onSubmit = async (values) => {
    try {
      shareMutation.mutate(
        {
          stakeholder_id: editId?.id,
          amount: values.amount,
          model: model,
        },
        {
          onSuccess: (responseData) => {
            onClose();
            toast({
              title: "Created successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            onClose();
          },
          onError: (responseDataError) => {
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                " not created",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        shareMutation.reset();
      }}
      size="2xl"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        <Center
          p={10}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <Center color="" mb={4} fontSize="25px" fontWeight="semibold">
              Create PlowBack
            </Center>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(values) => {
              return (
                <Form>
                  <Box
                    my={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text color="#AFAFAF" fontSize="15px" fontWeight="bold">
                      Shareholder name : {editId?.stakeholder?.name}
                    </Text>
                    <Text color="#AFAFAF" fontSize="15px" fontWeight="bold">
                      Current Capital : {editId?.stakeholder?.current_capital}
                    </Text>
                    <Divider color="#333" my={4} />
                  </Box>
                  <Wrap
                    fontSize="18px"
                    justify="center"
                    spacing={4}
                    direction={"column"}
                  >
                    <Field name="amount">
                      {({ field, form }) => (
                        <CustomInputField
                          field={field}
                          form={form}
                          title="Amount"
                          htmlFor="amount"
                          type={"number"}
                          error={values.errors.amount}
                          touched={values.touched.amount}
                        />
                      )}
                    </Field>
                  </Wrap>

                  <Center>
                    <Button
                      my={5}
                      colorScheme="facebook"
                      disabled={values.errors.amount}
                      _hover={
                        values.isValid &&
                        values.dirty && {
                          opacity: "0.8",
                        }
                      }
                      type={!shareMutation?.isLoading ? "submit" : "button"}
                      isLoading={shareMutation?.isLoading}
                    >
                      Create
                    </Button>
                  </Center>
                </Form>
              );
            }}
          </Formik>
        </Center>
      </ModalContent>
    </Modal>
  );
};

export default CreatePlowBack;
