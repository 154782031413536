import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  Button,
  HStack,
} from "@chakra-ui/react";

const ErrorModal = (props) => {
  const { isOpen, onClose, error } = props;
  // if(error){
  //   onOpen()
  // }

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Alert </ModalHeader>
          <ModalBody>
            {error
              ? error
              : "Login failed. Make sure you are connected to internet."}
          </ModalBody>
          <ModalFooter>
            <HStack>
              {error ===
                "Dear customer, first register as a user of the service by sending OK to 7595" && (
                <a
                  href={
                    getMobileOperatingSystem() === "iOS"
                      ? "sms:7595&body=ok"
                      : "sms:7595?body=ok"
                  }
                >
                  <Button
                    bgColor="#4482ad"
                    color="white"
                    _hover={{
                      color: "#4482ad",
                      bgColor: "white",
                      border: "1px #4482ad solid",
                    }}
                  >
                    Send
                  </Button>
                </a>
              )}
              <Button onClick={onClose}> Close </Button>
            </HStack>
            {/* <Button width='100%' onClick={onClose}>
              Close
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ErrorModal;
