import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import Select from "react-select";
import * as Yup from "yup";

import axios from "axios";
import { Field, Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CustomInputField from "../../../utilities/components/CustomInputField";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import { NomineeCard } from "../../components/NomineeCard";
export default function CreateSharerequest() {
  const [selectedOption, setselectedOption] = React.useState([]);
  const [options, setoptions] = React.useState([]);
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const PaymentMutation = useMutation(
    async (newData) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "request-share",
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  console.log({ selectedOption });
  const PaymentMutationHandler = async (values) => {
    try {
      PaymentMutation.mutate(
        {
          stakeholder_id: values.shareholder.id,
          requested_share: values.amount,
          requested_date: values.date,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: "payment created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/Share_request");
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: "payment not created.",
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const getStakeHoldername = useQuery(
    "getStakeHoldername",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      {" "}
      <HStack
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        w={"100%"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <Heading mb={"40px"}> Share Request</Heading>

          <Formik
            initialValues={{
              amount: "",
              shareholder: "",
            }}
            validationSchema={Yup.object({
              amount: Yup.number().required("Required !"),
              shareholder: Yup.object().required("Required !"),
              date: Yup.date().required("Required !"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              PaymentMutationHandler(values);
              console.log({ values });
              setSubmitting(false);
            }}
          >
            {(formik) => {
              console.log({ formik });
              return (
                <form onSubmit={formik.handleSubmit}>
                  <FormControl py={2} textAlign={"left"}>
                    <FormLabel>Select Stakeholder</FormLabel>
                    <Select
                      isClearable
                      styles={{
                        control: (base, state) => ({
                          ...base,
                        }),
                        container: (base, state) => ({
                          ...base,
                        }),
                      }}
                      placeholder="select"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(newSelection) => {
                        console.log(newSelection.id);
                        handleChange(newSelection);
                        formik?.setFieldValue("shareholder", newSelection);
                      }}
                      options={getStakeHoldername?.data?.data?.data}
                    />
                  </FormControl>
                  <Field name="amount">
                    {({ field, form }) => (
                      <CustomInputField
                        field={field}
                        form={form}
                        id={"amount"}
                        label={"Amount"}
                        placeholder={"Amount"}
                        htmlFor={"amount"}
                        error={form.errors.amount}
                        type={"number"}
                      />
                    )}
                  </Field>

                  <Field name="date">
                    {({ field, form }) => (
                      <CustomInputField
                        field={field}
                        form={form}
                        id={"date"}
                        label={"date"}
                        placeholder={"date"}
                        htmlFor={"date"}
                        error={form.errors.date}
                        type={"date"}
                      />
                    )}
                  </Field>
                  <Button
                    disabled={formik.isSubmitting || !formik.isValid}
                    mt={6}
                    type="submit"
                    bg={"#B76A1D"}
                    color={"white"}
                    variant="solid"
                  >
                    Submit
                  </Button>
                </form>
              );
            }}
          </Formik>
        </Box>
        {JSON.stringify(selectedOption)}
        {selectedOption?.id && (
          <NomineeCard
            name={selectedOption?.name}
            capital={selectedOption?.current_capital}
            boughtShareCount={selectedOption?.bought_shares_count}
            share={selectedOption?.shares}
            dividend={selectedOption?.dividend}
            soldShareCount={selectedOption?.sold_shares_count}
            viewOnly={true}
          />
        )}
      </HStack>
    </Stack>
  );
}
