import { FormControl } from "@chakra-ui/form-control";
import { AddIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/layout";
import { Box, Button, Center, Stack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import SatckholdersTable from "./Table/SatckholdersTable";

export default function Shareholder() {
  const [filter, setFilter] = React.useState(null);
  let navigate = useNavigate();
  return (
    <Stack padding={10}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {/* <Box
          sx={{
            width: "50%",
          }}
        >
          <FormControl
            sx={{
              width: "50%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
            }}
            py={2}
            textAlign={"flex-start"}
          >
            <Text
              sx={{
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              Select Stakeholder
            </Text>
            <Select
              styles={{
                menuPortal: (base) => ({
                  zIndex: 999999,
                }),
                menu: (provided) => ({ ...provided, zIndex: 999999 }),
                container: (provided, state) => ({
                  ...provided,
                  zIndex: 999999,
                  width: "100%",
                }),
              }}
              placeholder=" Filter Stakeholder"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(newSelection) => {
                console.log(newSelection);
                setFilter(newSelection);
              }}
              options={[
                { value: "0", label: "All" },
                { value: "1", label: "Influential" },
                { value: "2", label: "regular" },
                {
                  value: "3",
                  label: "capital growth",
                },
                {
                  value: "4",
                  label: "Share",
                },
              ]}
            />
          </FormControl>
        </Box> */}
        {/* <Box> */}
        <Button
          mt={"20px"}
          mb={"20px"}
          // ml={"10px"}
          mr={"20px"}
          float={"right"}
          onClick={() => {
            navigate("/Stake-holder/CreateStakeholder");
          }}
          // w={"30vh"}
          leftIcon={<AddIcon />}
          bg={"#B76A1D"}
          color={"white"}
          variant="solid"
        >
          Create Shareholder
        </Button>

        <Button
          mt={"20px"}
          mb={"20px"}
          mr={"20px"}
          float={"right"}
          onClick={() => {
            navigate("/Stake-holder/Createplowback");
          }}
          // w={"30vh"}
          leftIcon={<AddIcon />}
          bg={"#B76A1D"}
          color={"white"}
          variant="solid"
        >
          Create Activity
        </Button>
        {/* </Box> */}
      </Box>

      <Center>
        <SatckholdersTable></SatckholdersTable>
      </Center>
    </Stack>
  );
}
