import {
  Box,
  Button,
  Center,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import { MdOutlinePayments } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CashoutTable from "./Table/Cashouttable";
import PaymentTable from "./Table/PaymentTable";

export default function Payments() {
  let navigate = useNavigate();
  return (
    <Stack px={10} mt={10}>
      <Tabs>
        <TabList>
          <Tab>Payment</Tab>
          <Tab>Cashout</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Center>
              <PaymentTable></PaymentTable>
            </Center>
          </TabPanel>
          <TabPanel>
            <Stack>
              <Box>
                <Button
                  mt={"20px"}
                  mb={"20px"}
                  ml={"10px"}
                  mr={"20px"}
                  float={"right"}
                  onClick={() => {
                    navigate("/payments/Createpayments", {
                      state: {
                        state: null,
                        isReadOnly: false,
                        isEdit: false,
                        isShow: false,
                      },
                    });
                  }}
                  // w={"30vh"}
                  leftIcon={<MdOutlinePayments />}
                  bg={"#B76A1D"}
                  color={"white"}
                  variant="solid"
                >
                  Payment Cashout
                </Button>
              </Box>
              <Center>
                <CashoutTable />
              </Center>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
