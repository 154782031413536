import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { BiShowAlt } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import { RiEdit2Line } from "react-icons/ri";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Loader from "../../../assets/Loader";
import { useAuth } from "../../../utilities/hooks/auth-hook";

export default function ProfitTable() {
  const { useState } = React;
  const { token } = useAuth();
  let navigate = useNavigate();
  const [Id, setId] = useState(null);
  const [IdT, setIdT] = useState(null);
  const [finalizeId, setFinalizeId] = useState(null);

  const [data, setData] = useState([]);
  const toast = useToast();
  // const [count, setCount] = useState(0);
  // const [queryReload, setQueryReload] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  function DropMenu({ rowData }) {
    return (
      <Menu>
        <MenuButton
          rounded={"sm"}
          size={"xs"}
          as={IconButton}
          aria-label="Options"
          icon={
            <IconButton
              size={"xs"}
              rounded={"sm"}
              icon={<BsThreeDots />}
              variant={"outline"}
              colorScheme={"blackAlpha"}
            />
          }
          variant="outline"
        />
        <MenuList>
          <MenuItem
            icon={<BiShowAlt />}
            onClick={() =>
              navigate("/dividend/createprofit", {
                state: {
                  state: rowData,
                  isReadOnly: true,
                  isEdit: false,
                  isShow: true,
                },
              })
            }
          >
            Show
          </MenuItem>
          <MenuItem
            icon={<RiEdit2Line />}
            onClick={() =>
              navigate("/dividend/createprofit", {
                state: {
                  state: rowData,
                  isReadOnly: false,
                  isEdit: true,
                  isShow: false,
                },
              })
            }
          >
            Edit
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }
  const getProfits = useQuery(
    "getProfits",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}profits`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  // console.log({ count, queryReload, getProfits });
  const getProfitsfinalize = useQuery(
    [`getProfitsfinalize`, Id],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}profits/${finalizeId}/finalize`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token && finalizeId !== null,
      onSuccess: (res) => {
        getProfits?.refetch();
        toast({
          title: "Profite Finalized successfully",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        setId(null);
        setFinalizeId(null);
      },
      onError: (err) => {
        console.log(err);

        Id !== null &&
          toast({
            title: "Profite not Finalized ",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        setFinalizeId(null);
        setId(null);
      },
    }
  );
  React.useEffect(() => {
    if (finalizeId !== null) {
      getProfitsTransfer.refetch();
    }
  }, [finalizeId]);
  const getProfitsTransfer = useQuery(
    [`getProfitsTransfer`, IdT],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}transfer-remaining-amount?profit_id=${IdT}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: IdT !== null,
      onSuccess: (res) => {
        getProfits?.refetch();
        toast({
          title: "Transfered  successfully",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
      },
      onError: (err) => {
        console.log(err);

        Id !== null &&
          toast({
            title: "Profite not Finalized ",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
      },
    }
  );
  console.log({ getProfits });
  console.log({
    rowdata: Id,
    id: finalizeId,
  });
  const [columns, setColumns] = useState([
    { title: "Start Date", field: "start_date" },
    { title: "End Date", field: "end_date" },
    {
      title: " Profit Amount",
      field: "money_amount",
    },
    {
      title: " Tax Start Date",
      field: "tax_start_date",
    },
    {
      title: " Tax  ",
      field: "tax",
      render: (rowData) => {
        return <span>{`${rowData.tax * 100} %`}</span>;
      },
    },
    // {
    //   title: " Remaining_Share_amount",
    //   field: "remaining_amount",
    // },

    // {
    //   title: "Transfer Remaining Share",
    //   render: (rowData) => {
    //     return (
    //       rowData.remaining_amount !== "0" && (
    //         <Button
    //           mt={"20px"}
    //           mb={"20px"}
    //           ml={"10px"}
    //           mr={"20px"}
    //           bg={"#B76A1D"}
    //           color={"white"}
    //           variant="solid"
    //           onClick={() => {
    //             setIdT(rowData.id);
    //           }}
    //         >
    //           Transfer
    //         </Button>
    //       )
    //     );
    //   },
    // },
    {
      title: "Finalize",
      render: (rowData) => {
        return rowData.is_finalized == 0 ? (
          <Button
            mt={"20px"}
            mb={"20px"}
            ml={"10px"}
            mr={"20px"}
            bg={"#B76A1D"}
            color={"white"}
            variant="solid"
            onClick={() => {
              onOpen();
              setId(rowData.id);
            }}
          >
            Finalize
          </Button>
        ) : (
          <Text>Finalized</Text>
        );
      },
    },
    {
      title: "Action",
      render: (rowData) => {
        return <DropMenu rowData={rowData} />;
      },
    },
  ]);

  // const [data, setData] = useState([
  //   {
  //     grand: "test",
  //     code: 11111,
  //     color: "red",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 2222,
  //     color: "green",
  //     price: "100",
  //     quantity: "104,455,005",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 33333,
  //     color: "blue",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  // ]);
  return (
    <>
      <Box w={"100%"}>
        {getProfits?.isFetching ? (
          <Loader />
        ) : (
          <MaterialTable
            title="Profit List"
            columns={columns}
            data={data}
            options={{
              filtering: true,

              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#E82481",
                color: "#FFF",
              },
            }}
          />
        )}
      </Box>
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Finalize Profit
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    setFinalizeId(Id);
                    onClose();
                  }}
                  ml={3}
                >
                  Finalize
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    </>
  );
}
