import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { BiShowAlt } from "react-icons/bi";
import { RiEdit2Line } from "react-icons/ri";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Loader from "../../../assets/Loader";
import { useAuth } from "../../../utilities/hooks/auth-hook";

import { AiOutlineSmallDash } from "react-icons/ai";
import CreatePlowBack from "../CreatePlowBackModal";
export default function DividendTable() {
  const { useState } = React;
  const [selectedOption, setselectedOption] = React.useState([]);

  const [options, setoptions] = React.useState([]);

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setselectedOption(selectedOption);
    // getDividends.refetch();
  };
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [profit, setProfite] = useState(null);
  const [model, setModel] = useState(null);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getDividends = useQuery(
    `getDividends ${selectedOption.id}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dividends?profit_id=${selectedOption.id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data.data.dividend);
        setProfite(res?.data?.data?.profit?.money_amount);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const [columns, setColumns] = useState([
    { title: "Name", field: "stakeholder.name" },
    // {
    //   title: "Year ",
    //   field: "year",
    // },
    {
      title: "Paid-up Capital ",
      field: "",
      render: (dividend) =>
        `${Math.round(
          dividend.stakeholder?.current_capital
        ).toLocaleString()} ETB`,
    },

    {
      title: "Dividend ",
      field: "total_payable",
      // render: (dividend) => Number(dividend?.total_payable).toLocaleString(),
      render: (dividend) =>
        ` ${(
          Math.round(dividend.total_payable * 100) / 100
        ).toLocaleString()} ETB`,
    },
    {
      title: "Payable ",
      field: "payable",
      render: (dividend) =>
        `${Math.round(
          Number(dividend?.stakeholder?.dividend).toFixed(2)
        ).toLocaleString()} ETB`,
    },

    {
      title: "Net Dividend ",
      field: "stakeholder.dividend",
      render: (amount) =>
        `${Number(
          amount?.stakeholder?.dividend + amount?.total_payable
        ).toLocaleString()} ETB`,
    },
    {
      title: "Action",
      render: (rowData) => {
        return <DropMenu rowData={rowData} />;
      },
    },
  ]);

  const getyear = useQuery(
    "getyear",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}finalized-profits`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions([]);

        for (let i = 0; i < res.data.data.length; i++) {
          console.log(i);

          setoptions((prevOpt) =>
            prevOpt.concat({
              title: `${res.data.data[i].start_date} - ${res.data.data[i].end_date} `,
              id: `${res.data.data[i].id}`,
            })
          );
        }

        // setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editId, setEditId] = useState(null);
  function DropMenu({ rowData }) {
    return (
      <Menu>
        <MenuButton
          rounded={"sm"}
          size={"xs"}
          as={IconButton}
          aria-label="Options"
          icon={
            <IconButton
              size={"md"}
              rounded={"sm"}
              icon={<AiOutlineSmallDash />}
              variant={"outline"}
            />
          }
          variant="outline"
        />
        <MenuList
          sx={{
            bgColor: "gray.50",
          }}
        >
          <MenuItem
            icon={<BiShowAlt />}
            onClick={() =>
              navigate("/dividend/createDividend", {
                state: {
                  state: rowData,
                  isReadOnly: true,
                  isEdit: false,
                  isShow: true,
                },
              })
            }
          >
            Show
          </MenuItem>
          <MenuItem
            sx={{
              color: "red.500",
            }}
            icon={<RiEdit2Line />}
            // onClick={() =>
            //   navigate("/Stake-holder/Createplowback", {
            //     state: {
            //       state: rowData,
            //       isReadOnly: false,
            //       isEdit: true,
            //       isShow: false,
            //     },
            //   })
            // }
            onClick={() => {
              // onOpen();
              setEditId(rowData);
            }}
          >
            Plowback
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }
  const getCaptitalG = useQuery(
    "getCaptitalG",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}capital-growths`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        var id = res.data.data[0].model;
        setModel(id);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  React.useEffect(() => {
    if (getCaptitalG?.data?.data?.length > 0) {
      // console.log(getCaptitalG?.data?.data[0]?.model);
      setModel(getCaptitalG?.data?.data[0]?.model);
    }
  }, [getCaptitalG?.data?.data]);

  const onCancel = () => {
    setEditId(false);
    onClose();
  };

  return (
    <Box w={"100%"}>
      <CreatePlowBack
        editId={editId}
        isOpen={editId}
        onClose={onCancel}
        model={model}
      />

      <HStack
        spacing={8}
        sx={{
          // border: "1px solid red",
          justifyContent: "space-around",
        }}
      >
        <Box
          align={"left"}
          justifyContent={"left"}
          // borderWidth={"2px"}
          w={"34vh"}
          paddingLeft={"10px"}
        >
          <FormControl zIndex={50} py={2} textAlign={"left"}>
            <FormLabel fontWeight={"bolder"}>Select Year</FormLabel>
            <Select
              styles={{ textAlign: "left" }}
              w={"20vh"}
              // id={"nameid"}
              placeholder="select"
              getOptionLabel={(options) => options.title}
              getOptionValue={(options) => options.id}
              value={selectedOption}
              onChange={(newSelection) => {
                console.log(newSelection);
                handleChange(newSelection);
              }}
              options={options}
            />
          </FormControl>
        </Box>
        {profit !== null && (
          <Text fontWeight={"bolder"} justifyContent={"end"}>
            Total Profit: {Number(profit).toLocaleString("en-US")} ETB
          </Text>
        )}
      </HStack>
      {getDividends?.isFetching ? (
        <Loader />
      ) : (
        <Stack>
          <MaterialTable
            title="Dividend List"
            columns={columns}
            data={data}
            options={{
              pageSizeOptions: [10, 15, 20, 30, 50, 75, 100],
              pageSize: 10,
              filtering: true,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#E82481",
                color: "#FFF",
              },
            }}
          />
        </Stack>
      )}
    </Box>
  );
}
