import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Stack,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";

import axios from "axios";
import MaterialTable from "material-table";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Loader from "../../assets/Loader";
import { useAuth } from "../../utilities/hooks/auth-hook";
import AddUserModal from "./components/AddUserModal";
import EditUserModal from "./components/EditUserModal";

const ManageAllUsers = () => {
  const [editId, setEditId] = useState(false);
  const [detailId, setDetailId] = useState(false);
  const [isSmallerThan768] = useMediaQuery("(max-width: 768px)");
  const [passChange, setPassChange] = useState(null);

  const [showCheck, setShowCheck] = useState(false);

  const [isSmallerThan992] = useMediaQuery("(max-width: 992px)");
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { token, type } = useAuth();

  const [userQueryReload, setUsersQueryReload] = useState(false);

  const [count, setCount] = useState(0);
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const deleteUserMutation = useMutation(
    async (deleteUser) =>
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + `users/${deleteUser?.id}`,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const usersData = useQuery(
    "usersData",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}users`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: count === 0 ? !!token : userQueryReload && !!token,
    }
  );
  const queryClient = useQueryClient();

  const confirmDeleteHandler = async (newData) => {
    const dataDelete = [...usersData?.data.data];
    const index = newData.tableData.id;
    dataDelete.splice(index, 1);
    setCount(count + 1);
    try {
      deleteUserMutation.mutate(
        {
          id: newData?.id,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: "User deleted successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setUsersQueryReload(true);
            setUsersQueryReload(false);
            queryClient.invalidateQueries("usersData");
          },
          onError: (responseData) => {
            toast({
              title:
                responseData?.response?.data?.message || "User not deleted!",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };
  const updateUserStatus = useQuery(
    "updateUserStatus",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user-status/${userId}/update`,
        {
          headers,
        }
      );
      return response.data; // Return the response data
    },
    {
      enabled: !!userId,
      retry: false,
      onSuccess: (data) => {
        console.log("Update user status successful:", data);
        toast({
          title: "User status updated successfully",
          status: "success",
          duration: 5000,

          isClosable: true,
        });
        setUsersQueryReload(true);
        setUserId(null);
        queryClient.invalidateQueries("usersData");
      },
    }
  );

  React.useEffect(() => {
    if (userId) {
      updateUserStatus.refetch();
    }
  }, [userId]);

  const loadedColumns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Phone number",
      field: "phone",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Role",
      field: "role",
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return (
          <Switch
            size="lg"
            colorScheme="#B76A1D"
            defaultChecked={rowData?.status === "1" ? true : false}
            value={rowData?.status === "1" ? true : false}
            isChecked={rowData?.status === "1" ? true : false}
            sx={{
              "& .chakra-switch__track": {
                borderColor: "#B76A1D",
              },
              "& .chakra-switch__thumb": {
                bg: "#B76A1D",
              },
            }}
            // onChange={() => {
            //   setUserId(rowData?.id);
            // }}
          />
        );
      },
    },
    {
      title: "",
      render: (rowData) => {
        return (
          <HStack>
            {/* <Button
              size={"sm"}
              disabled={usersData?.isFetching ? true : false}
              onClick={() => {
                setDetailId(rowData?.id);
              }}
              border="1px solid #006ddb"
            >
              <BiDetail size="1.4em" />
            </Button>{" "} */}
            <Tooltip
              label="Edit User"
              aria-label="A tooltip"
              p={2}
              fontSize={"16px"}
              fontWeight={"bold"}
            >
              <Button
                display={type === "ADMIN" ? "flex" : "none"}
                disabled={usersData?.isFetching ? true : false}
                size={"sm"}
                onClick={() => {
                  setShowCheck(rowData?.is_main === 1 ? true : false);
                  setEditId(rowData?.id);
                }}
                border="1px solid #006ddb"
              >
                <AiFillEdit />
              </Button>
            </Tooltip>
            {/* <Button
              disabled={usersData?.isFetching ? true : false}
              size={"sm"}
              onClick={() => {
                setPassChange(rowData?.id);
              }}
              border="1px solid #006ddb"
            >
              <RiLockPasswordFill />
            </Button> */}
            <Box display={type === "ADMIN" ? "flex" : "none"}>
              <Tooltip
                label="Delete User"
                aria-label="A tooltip"
                color={"white"}
                bg={"red"}
                p={2}
                fontSize={"16px"}
                fontWeight={"bold"}
              >
                <Button
                  disabled={usersData?.isFetching ? true : false}
                  size={"sm"}
                  border="1px solid #006ddb"
                  onClick={() => {
                    deleteUserMutation.mutate(rowData, {
                      onSuccess: (responseData) => {
                        toast({
                          title: "User deleted successfully",
                          status: "success",
                          duration: 5000,
                          isClosable: true,
                        });
                        setUsersQueryReload(true);
                        setUsersQueryReload(false);
                        queryClient.invalidateQueries("usersData");
                      },
                      onError: (responseData) => {
                        toast({
                          title:
                            responseData?.response?.data?.message ||
                            "User not deleted!",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                      },
                    });
                  }}
                >
                  <AiOutlineDelete />
                </Button>
              </Tooltip>
            </Box>
          </HStack>
        );
      },
    },
  ];

  const onCancel = () => {
    setEditId(false);
    setShowCheck(false);
    setDetailId(false);
    setPassChange(null);
  };

  console.log(usersData?.data?.data?.data?.users, "users");
  return (
    <Box fontSize="14px" w="100%" px="2%">
      <AddUserModal
        setCount={setCount}
        setUsersQueryReload={setUsersQueryReload}
        isOpen={isOpen}
        onClose={onClose}
      />
      <EditUserModal
        setUsersQueryReload={setUsersQueryReload}
        count={count}
        setCount={setCount}
        editId={editId}
        isOpen={!!editId}
        onClose={onCancel}
        setShowCheck={setShowCheck}
        showCheck={showCheck}
      />
      {!usersData?.isLoading ? (
        <>
          <Stack alignSelf="center">
            <Flex direction="column">
              <Box>
                <HStack mb={1}>
                  <Box flexGrow="1" />
                  <Button
                    bg={"#385898"}
                    display={type === "ADMIN" ? "flex" : "none"}
                    color={"white"}
                    border="1px solid #385898"
                    p={6}
                    fontSize="18px"
                    _hover={{
                      bg: "#FFF",
                      color: "#385898",
                    }}
                    size="sm"
                    onClick={onOpen}
                  >
                    Add User
                  </Button>
                </HStack>
                {usersData?.isSuccess ? (
                  <MaterialTable
                    title={
                      usersData?.isFetching ? (
                        <HStack>
                          <Text> Loading updated data </Text>
                        </HStack>
                      ) : (
                        "Users List"
                      )
                    }
                    columns={loadedColumns}
                    data={usersData?.data?.data?.data?.users || []}
                    options={{
                      fixedColumns: isSmallerThan768 && {
                        left: 1,
                        right: 0,
                      },
                      pageSize: 5,
                      filtering: true,
                      exportButton:
                        !usersData?.isFetching && !isSmallerThan992
                          ? true
                          : false,
                    }}
                  />
                ) : (
                  <Center>Check your connection and refresh </Center>
                )}
              </Box>
            </Flex>
          </Stack>
        </>
      ) : (
        <Loader h={"90vh"} />
      )}
      <Divider borderColor="gray.600" />
    </Box>
  );
};

export default ManageAllUsers;
