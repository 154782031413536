import {
  Box,
  Button,
  FormControl,
  Heading,
  SimpleGrid,
  Stack,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import FormComp from "../../utilities/components/FormComp";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useParams } from "react-router";
import Loader from "../../assets/Loader";
export default function UpdateStakeholder() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const updateMutation = useMutation(
    async (newData) =>
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + "stakeholders",
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const updateMutationHandler = async (values) => {
    const {
      phone,
      status,
      dividend,
      capital,
      first_name,
      middle_name,
      last_name,
      email,
      gender,
      address,
      id_number,
      first_name_amharic,
      middle_name_amharic,
      last_name_amharic,
      address_amharic,
    } = values;
    // try {
    updateMutation.mutate(
      {
        phone,
        status,
        dividend,
        capital,
        first_name,
        middle_name,
        last_name,
        email,
        gender: gender.value,
        address,
        id_number,
        first_name_amharic,
        middle_name_amharic,
        last_name_amharic,
        address_amharic,
      },
      {
        onSuccess: (responseData) => {
          toast({
            title: "Stakeholder created successfully",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          navigate("/Stake-holder");
        },
        onError: () => {
          toast({
            title: "Stakeholder not created.",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        },
      }
    );
    // } catch (err) {
    //   toast({
    //     title: "Error when Creating",
    //     status: "error",
    //     duration: 2500,
    //     isClosable: true,
    //   });
    // }
  };
  const params = useParams();

  const getStakeHolderData = useQuery(
    "shareholderdata",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}stakeholder-detail/${params?.id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );
  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"80%"}>
        <Heading> Update Shareholder</Heading>
        {getStakeHolderData?.isFetching ? (
          <Loader />
        ) : (
          <Formik
            initialValues={{
              capital: getStakeHolderData?.data?.data?.data?.current_capital,
              dividend: getStakeHolderData?.data?.data?.data?.dividend,
              phone: getStakeHolderData?.data?.data?.data?.phone,
              status: getStakeHolderData?.data?.data?.data?.status,
              email: getStakeHolderData?.data?.data?.data?.data?.email,
              first_name: getStakeHolderData?.data?.data?.data?.name,
              middle_name: getStakeHolderData?.data?.data?.data?.middle_name,
              last_name: getStakeHolderData?.data?.data?.data?.last_name,
              first_name_amharic:
                getStakeHolderData?.data?.data?.data?.first_name_amharic,
              middle_name_amharic:
                getStakeHolderData?.data?.data?.data?.middle_name_amharic,
              last_name_amharic:
                getStakeHolderData?.data?.data?.data?.last_name_amharic,
              address_amharic:
                getStakeHolderData?.data?.data?.data?.address_amharic,
              address: getStakeHolderData?.data?.data?.data?.address,
              id_number: getStakeHolderData?.data?.data?.data?.id_number,
            }}
            validationSchema={Yup.object({
              first_name: Yup.string().required("Required !"),
              middle_name: Yup.string().required("Required !"),
              last_name: Yup.string().required("Required !"),
              capital: Yup.string().required("Required !"),
              phone: Yup.string().required("Required !"),
              status: Yup.bool().required("Required !"),
              email: Yup.string().email("Invalid email"),
              gender: Yup.object(),
              address: Yup.string().required("Required !"),
              id_number: Yup.string().required("Required !"),
              first_name_amharic: Yup.string().required("required !"),
              middle_name_amharic: Yup.string().required("required !"),
              last_name_amharic: Yup.string().required("required !"),
              address_amharic: Yup.string().required("required !"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              updateMutationHandler(values);
              console.log({ values });
              setSubmitting(false);
            }}
          >
            {(formik) => {
              console.log({ formik });
              return (
                <form onSubmit={formik.handleSubmit}>
                  <SimpleGrid columns={2} spacing={7}>
                    <FormComp
                      id={"first_name"}
                      label={"First Name"}
                      formik={formik}
                    />
                    <FormComp
                      id={"first_name_amharic"}
                      label={"First Name Amharic"}
                      formik={formik}
                      readOnly={true}
                    />
                    <FormComp
                      id={"middle_name"}
                      label={"Middle Name"}
                      formik={formik}
                    />
                    <FormComp
                      id={"middle_name_amharic"}
                      label={"Middle Name Amharic"}
                      formik={formik}
                    />
                    <FormComp
                      id={"last_name"}
                      label={"Last Name"}
                      formik={formik}
                    />
                    <FormComp
                      id={"last_name_amharic"}
                      label={"Last Name Amharic"}
                      formik={formik}
                    />
                    <FormComp id={"phone"} label={"Phone"} formik={formik} />
                    <FormComp
                      id={"capital"}
                      label={"Capital(birr)"}
                      formik={formik}
                      type={"number"}
                    />
                    <FormComp
                      id={"dividend"}
                      label={"dividend(birr)"}
                      formik={formik}
                      type={"number"}
                    />
                    <FormComp id={"email"} label={"Email"} formik={formik} />
                    <FormComp
                      id={"address"}
                      label={"Address"}
                      formik={formik}
                    />
                    <FormComp
                      id={"address_amharic"}
                      label={"Address Amharic"}
                      formik={formik}
                    />
                    <FormComp
                      id={"id_number"}
                      label={"ID Number"}
                      formik={formik}
                    />
                    <FormControl
                      sx={{
                        width: "70%",
                        mx: "auto",
                        justifyContent: "center",
                      }}
                      py={2}
                      textAlign={"center"}
                    >
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderRadius: "5px",
                            width: "90%",
                            height: "5vh",
                            boxShadow: "none",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? "white" : "#B76A1D",
                            background: state.isSelected ? "#B76A1D" : "white",
                            padding: 10,
                          }),
                          container: (provided, state) => ({
                            ...provided,
                          }),
                        }}
                        w={"100%"}
                        // id={"nameid"}
                        placeholder="gender"
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={formik.values.gender}
                        options={[
                          {
                            value: "male",
                            label: "Male",
                          },
                          {
                            value: "female",
                            label: "Female",
                          },
                        ]}
                        onChange={(value) =>
                          formik.setFieldValue("gender", value)
                        }
                      />
                    </FormControl>{" "}
                    <FormControl>
                      {" "}
                      <Checkbox
                        id={"status"}
                        value={formik.values.status}
                        checked={formik.values.status}
                        colorScheme="green"
                        onChange={(value) => {
                          formik.setFieldValue("status", !formik.values.status);
                        }}
                      >
                        Status
                      </Checkbox>{" "}
                    </FormControl>{" "}
                  </SimpleGrid>
                  <Button
                    disabled={formik.isSubmitting || !formik.isValid}
                    mt={6}
                    bg={"#B76A1D"}
                    color={"white"}
                    variant="solid"
                    type={"submit"}
                    isLoading={updateMutation?.isLoading}
                    // onClick={() => {
                    //   updateMutationHandler();
                    // }}
                  >
                    Submit
                  </Button>
                </form>
              );
            }}
          </Formik>
        )}
      </Box>
    </Stack>
  );
}
