import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [type, setType] = useState("NOAUTH"); // "SCHOOL" "PARENT" "BRANCH" "NOAUTH"
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [checked, setChecked] = useState(false);
  const [privileges, setPrivileges] = useState([]);

  const myUserPermissions = useQuery(
    `myUserPermissions${user?.id}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}users/user-permissions/${user?.id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token && !!user?.id,
      onSuccess: (res) => {
        setPrivileges(res?.data?.data);
      },
    }
  );

  //Function
  const login = useCallback((type, token, user, privileges) => {
    setType(type);
    setToken(token);
    setUser(user);
    setPrivileges(privileges);
    localStorage.setItem(
      "honbonAuthData",
      JSON.stringify({
        type,
        token,
        user,
        privileges,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setType("NOAUTH");
    setToken(null);
    setPrivileges([]);
  }, []);

  let auth;

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("honbonAuthData"));
    console.log({ storedData });
    if (storedData) {
      if (storedData?.token) {
        auth = login(
          storedData?.type,
          storedData?.token,
          storedData?.user,
          storedData?.privileges
        );
      }
    } else {
      auth = login("NOAUTH", null, null, []);
    }
    setChecked(true);
  }, [auth]);

  //Return
  return (
    <AuthContext.Provider
      value={{ type, user, token, privileges, checked, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
