import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import Select from "react-select";
import * as Yup from "yup";
import FormComp from "../../utilities/components/FormComp";

import { Typography } from "@material-ui/core";
import axios from "axios";
import { Field, Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInputField from "../../utilities/components/CustomInputField";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { NomineeCard } from "../components/NomineeCard";
export default function CreateTransfers() {
  const [selectedOption, setselectedOption] = React.useState([]);
  const [selectedOption2, setselectedOption2] = React.useState([]);

  const [options, setoptions] = React.useState([]);

  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const handleChange2 = (selectedOption) => {
    setselectedOption2(selectedOption);
  };
  const {
    state: { state, isReadOnly, isEdit, isShow },
  } = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const TransfersMutation = useMutation(
    async (newData) =>
      isEdit
        ? await axios.patch(
            process.env.REACT_APP_BACKEND_URL + "transfers" + "/" + state.id,
            newData,
            {
              headers,
            }
          )
        : await axios.post(
            process.env.REACT_APP_BACKEND_URL + "transfers",
            newData,
            {
              headers,
            }
          ),
    {
      retry: false,
    }
  );
  const TransfersMutationHandler = async (values) => {
    const { amount, is_internal, reference, without_limit, transfer_date } =
      values;
    try {
      TransfersMutation.mutate(
        {
          from: selectedOption.id,
          to: selectedOption2.id,
          is_internal,
          amount: amount,
          reference,
          without_transfer_limit: without_limit,
          transfer_date,
        },

        {
          onSuccess: (responseData) => {
            toast({
              title: isEdit
                ? "transfers created successfully"
                : "Share created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/transfers");
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: `${err.data?.message}`,
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: `${err.message}`,
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const getStakeHoldername = useQuery(
    "getStakeHoldername",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const getTransfer = useQuery(
    ["gettransfer"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}transfer-limits`,
        {
          headers,
        }
        // {
        //   params: {
        //     latest: true,
        //   },
        // }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );

  console.log({ state });

  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"50%"} h={"60vh"}>
        <Heading mb={"40px"}> Transfer</Heading>
        <Formik
          initialValues={{
            transferor_name: state !== null ? state?.from?.name : "",
            receiver_name: state !== null ? state?.to?.name : "",
            amount: state !== null ? state?.money_amount : "",

            is_internal: true,
            reference: state !== null ? state?.reference_number : "",
            without_limit: false,
            transfer_date: state !== null ? state?.transfer_date : "",
          }}
          validationSchema={Yup.object({
            // nameid: Yup.string().required("Required !"),
            amount: Yup.number()
              .required("Required !")
              .max(
                state !== null
                  ? state.from.money_amount
                  : selectedOption?.current_capital
              ),

            reference: Yup.string().when("is_internal", {
              is: false,
              then: Yup.string().required("Required !"),
            }),
            without_limit: Yup.boolean().required("Required !"),
            transfer_date: Yup.string().required("Required !"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            TransfersMutationHandler(values);
            console.log({ values });
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log(formik);
            return (
              <form onSubmit={formik.handleSubmit}>
                {isEdit ? (
                  <>
                    <FormComp
                      id={"transferor_name"}
                      label={"Transfer name"}
                      formik={formik}
                      isReadOnly={isReadOnly}
                    />
                    {state?.to != null && (
                      <FormComp
                        id={"receiver_name"}
                        label={"Receiver name"}
                        formik={formik}
                        isReadOnly={isReadOnly}
                      />
                    )}
                  </>
                ) : isShow ? (
                  <>
                    <FormComp
                      id={"transferor_name"}
                      label={"Transferor name"}
                      formik={formik}
                      isReadOnly={isReadOnly}
                    />
                    <FormComp
                      id={"receiver_name"}
                      label={"Receiver name"}
                      formik={formik}
                      isReadOnly={isReadOnly}
                    />
                    <FormComp
                      id={"reference"}
                      label={"Reference Number"}
                      formik={formik}
                      isReadOnly={isReadOnly}
                    />
                  </>
                ) : (
                  <>
                    <FormControl
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                      py={2}
                      textAlign={"left"}
                      name="is_internal"
                    >
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                        }}
                        textAlign={"left"}
                      >
                        Select Transfer Type
                      </Typography>

                      <Select
                        w={"20vh"}
                        id={"is_internal"}
                        name={"is_internal"}
                        placeholder="select"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        defaultValue={formik?.values?.is_internal}
                        onChange={(newSelection) => {
                          console.log(newSelection.id);
                          formik?.setFieldValue("is_internal", newSelection.id);
                        }}
                        options={[
                          { id: true, name: "Shareholder" },
                          { id: false, name: "Legal Case" },
                        ]}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            backgroundColor: "white",
                            borderRadius: "20px",
                          }),

                          valueContainer: (provided) => ({
                            ...provided,
                            width: "100%",
                            height: "100%",
                            border: "none",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            width: "100%",
                            height: "100%",
                          }),
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "20px",
                            backgroundColor: "white",
                            border: "2px solid #EAEAEA",
                            width: "100%",
                            // background: "#EAEAEA",
                            padding: "0px 10px",

                            minHeight: "50px",

                            "&:hover": {
                              borderColor: "none",
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#0077FF"
                              : "transparent",
                            color: state.isSelected ? "white" : "#000",
                            zIndex: 999,

                            height: "100%",
                            "&:hover": {
                              backgroundColor: "#0077FF",
                              color: "white",
                            },
                          }),
                        }}
                      />
                    </FormControl>

                    <FormControl py={2} textAlign={"left"}>
                      <FormLabel>From</FormLabel>
                      <Select
                        styles={{ textAlign: "left" }}
                        w={"20vh"}
                        id={"name"}
                        // id={"nameid"}
                        placeholder="select"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={selectedOption}
                        onChange={(newSelection) => {
                          console.log(newSelection.id);
                          // formik?.setFieldValue("nameid", newSelection.id);
                          handleChange(newSelection);
                        }}
                        options={options}
                      />
                    </FormControl>

                    {!!formik?.values?.is_internal && (
                      <FormControl py={2} textAlign={"left"}>
                        <FormLabel>To </FormLabel>
                        <Select
                          styles={{ textAlign: "left" }}
                          w={"20vh"}
                          id={"name"}
                          // id={"nameid"}
                          placeholder="select"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={selectedOption2}
                          onChange={(newSelection) => {
                            console.log(newSelection.id);
                            // formik?.setFieldValue("nameid", newSelection.id);
                            handleChange2(newSelection);
                          }}
                          options={options}
                        />
                      </FormControl>
                    )}
                  </>
                )}
                {!formik?.values?.is_internal && (
                  <FormComp
                    id={"reference"}
                    label={"Reference Number"}
                    formik={formik}
                  />
                )}
                <FormComp
                  id={"amount"}
                  label={"Amount"}
                  formik={formik}
                  type="number"
                />
                <Field name="transfer_date">
                  {({ field, form }) => (
                    <CustomInputField
                      field={field}
                      form={form}
                      id={"transfer_date"}
                      label={"transfer_date"}
                      title={"Transfer Date"}
                      placeholder={"transfer date"}
                      htmlFor={"transfer_date"}
                      error={formik.errors.transfer_date}
                      touched={formik.touched.transfer_date}
                      type={"date"}
                    />
                  )}
                </Field>

                <Checkbox
                  float={"left"}
                  marginTop={"20px"}
                  fontSize={"22px"}
                  sx={{
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                  id={"without_limit"}
                  value={formik.values.prommised}
                  colorScheme="green"
                  checked={formik.values.without_limit}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "without_limit",
                      !formik.values.prommised
                    );
                  }}
                >
                  Transfer without limit
                </Checkbox>

                <br />
                <br />
                {isReadOnly === false && (
                  <Typography textAlign="flex-start">
                    {getTransfer?.data?.data?.data?.length > 0
                      ? `Note: Minimum transfer amount is : ${getTransfer?.data?.data?.data[0]?.minimum_amount}`
                      : "Minimum Transfer amount is not set yet"}
                  </Typography>
                )}
                {!isShow && (
                  <Button
                    disabled={
                      formik.isSubmitting ||
                      (formik.values.without_limit === false &&
                        !getTransfer?.data?.data?.data?.length > 0)
                    }
                    mt={6}
                    type="submit"
                    bg={"#B76A1D"}
                    color={"white"}
                    variant="solid"
                  >
                    Transfer
                  </Button>
                )}
              </form>
            );
          }}
        </Formik>
      </Box>
      <HStack
        sx={{
          width: "90%",
          margin: "auto",
          justifyContent: "space-around",
        }}
      >
        <Box>
          <Heading
            sx={{
              textAlign: "center",
              fontSize: "25px",
              fontWeight: "bolder",
            }}
          >
            {" "}
            From{" "}
          </Heading>
          {selectedOption?.id && (
            <NomineeCard
              name={selectedOption?.name}
              capital={selectedOption?.current_capital}
              boughtShareCount={selectedOption?.bought_shares_count}
              share={selectedOption?.shares}
              dividend={selectedOption?.dividend}
              soldShareCount={selectedOption?.sold_shares_count}
              viewOnly={true}
            />
          )}
        </Box>
        <Box>
          <Heading
            sx={{
              textAlign: "center",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            {" "}
            To{" "}
          </Heading>
          {selectedOption2?.id && (
            <NomineeCard
              name={selectedOption2?.name}
              capital={selectedOption2?.current_capital}
              boughtShareCount={selectedOption2?.bought_shares_count}
              share={selectedOption2?.shares}
              dividend={selectedOption2?.dividend}
              soldShareCount={selectedOption2?.sold_shares_count}
              viewOnly={true}
            />
          )}
        </Box>
      </HStack>
    </Stack>
  );
}
