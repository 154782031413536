import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Login/Login";
import StoreManagerRoutes from "./routes/StoreManagerRoutes";
import Error from "./storemanager/components/404";
import { useAuth } from "./utilities/hooks/auth-hook";

function App() {
  const { token } = useAuth();

  return (
    <div className="App">
      {token === null ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Error />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <StoreManagerRoutes />
      )}
    </div>
  );
}

export default App;
