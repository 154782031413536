import {
  Box,
  Button,
  Center,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Text,
  Heading,
  VStack,
  Checkbox,
  Wrap,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import SharerequestTable from "./Table/Share_requestTable";
import { FcApproval } from "react-icons/fc";
import axios from "axios";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import FreeShareTable from "./Table/FreeSharesTable";
import CustomInputField from "../../../utilities/components/CustomInputField";
import { Field, Form, Formik } from "formik";
import { CustomInput } from "../../../utilities/components/TaxUpdate";
import * as Yup from "yup";
export default function Sharerequest() {
  let navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOption, setselectedOption] = React.useState([]);
  const [profitedate, setprofitedate] = React.useState(new Date());

  const { token } = useAuth();
  const toast = useToast();

  const [options, setoptions] = React.useState([]);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
    // getDividends.refetch();
  };
  const freeShareTable = useQuery(
    "freeShareTable ",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}available-share?budjetYear=${
          profitedate.getFullYear() - 9
        }`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        // setData(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ freeShareTable });
  // const getAllowedsher = useQuery(
  //   "getAllowedsher",
  //   async () =>
  //     await axios.get(`${process.env.REACT_APP_BACKEND_URL}available-share`, {
  //       headers,
  //     }),
  //   {
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //     retry: false,
  //     enabled: !!token,
  //     onSuccess: (res) => {
  //       console.log(res.data.data);
  //       setoptions();

  //       // setoptions(res.data.data);
  //     },
  //     onError: (err) => {
  //       console.log(err);
  //     },
  //   }
  // );
  const PaymentMutation = useMutation(
    async (newData) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "allow-request-share",
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const ApproveShare = async () => {
    console.log({ selectedOption });
    try {
      PaymentMutation.mutate(
        {
          // profit_id: selectedOption.id,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: "Share request is approved successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/Share_request");
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: "Share request not approved",
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  // console.log({ getAllowedsher });
  const initialValues = {
    amount: "",
    is_finalized: false,
  };
  const validationSchema = Yup.object({
    amount: Yup.number().required("Required"),
    is_finalized: Yup.boolean().required("Required"),
  });
  const createShare = useMutation(
    async (newData) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "share-availables",
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const onSubmit = async (values) => {
    try {
      createShare.mutate(
        {
          ...values,
        },
        {
          onSuccess: (responseData) => {
            onClose();
            toast({
              title: "Created successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          },
          onError: (responseDataError) => {
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                " not created",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };
  return (
    <Stack padding={10}>
      <Tabs>
        <TabList>
          <Tab>Share Request</Tab>
          <Tab>Share Available</Tab>
          <Tab>Share Activity</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Stack>
              <Box>
                <Button
                  mt={"20px"}
                  mb={"20px"}
                  ml={"10px"}
                  mr={"20px"}
                  float={"right"}
                  onClick={() => {
                    navigate("/Share_request/Create_Share_request", {
                      state: {
                        state: null,
                        isReadOnly: false,
                        isEdit: false,
                        isShow: false,
                      },
                    });
                  }}
                  // w={"30vh"}
                  leftIcon={<AddIcon />}
                  bg={"#B76A1D"}
                  color={"white"}
                  variant="solid"
                >
                  Create Share Request
                </Button>
              </Box>
              <Center>
                <SharerequestTable />
              </Center>
            </Stack>
          </TabPanel>

          <TabPanel>
            <VStack>
              <VStack>
                <Center p={0}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {(values) => {
                      console.log(values);
                      return (
                        <Form>
                          <Box my={4}>
                            <Center fontSize="25px" fontWeight="semibold">
                              Create Share
                            </Center>
                          </Box>
                          <Wrap
                            fontSize="18px"
                            justify="center"
                            spacing={4}
                            direction={"column"}
                          >
                            <Field name="amount">
                              {({ field, form }) => (
                                <CustomInputField
                                  field={field}
                                  form={form}
                                  title="Amount"
                                  placeholder="Amount"
                                  type="number"
                                  htmlFor="amount"
                                  error={values.errors.amount}
                                  touched={values.touched.amount}
                                />
                              )}
                            </Field>
                          </Wrap>

                          <Wrap
                            justify="center"
                            spacing={4}
                            direction={"column"}
                          >
                            <Checkbox
                              float={"left"}
                              title="is_finalized"
                              id={"is_finalized"}
                              value={values.is_finalized}
                              colorScheme="green"
                              onChange={(value) => {
                                values.setFieldValue(
                                  "is_finalized",
                                  value.target.checked
                                );
                              }}
                              sx={{
                                mt: 6,
                              }}
                            >
                              Is Finalized
                            </Checkbox>
                          </Wrap>

                          <Center>
                            <Button
                              my={5}
                              colorScheme="facebook"
                              disabled={values.errors.amount}
                              _hover={
                                values.isValid &&
                                values.dirty && {
                                  opacity: "0.8",
                                }
                              }
                              isLoading={createShare?.isLoading}
                              type="submit"
                            >
                              Create Share
                            </Button>
                          </Center>
                        </Form>
                      );
                    }}
                  </Formik>
                </Center>
              </VStack>
            </VStack>
          </TabPanel>
          <TabPanel>
            <Stack>
              <HStack justify={"space-between"}>
                <Text>
                  Total Available Share:{" "}
                  {freeShareTable?.data?.data?.data?.TotalAvailable}{" "}
                </Text>
                <Box>
                  <Button
                    mt={"20px"}
                    mb={"20px"}
                    ml={"10px"}
                    mr={"20px"}
                    // disabled={
                    //   // getAllowedsher.data?.data?.data.share_amount !== "0"
                    // }
                    float={"right"}
                    onClick={onOpen}
                    // w={"30vh"}
                    leftIcon={<FcApproval />}
                    bg={"#B76A1D"}
                    color={"white"}
                    variant="solid"
                  >
                    Approve Share_request
                  </Button>
                </Box>
              </HStack>

              <Center>
                <FreeShareTable
                  data={freeShareTable?.data?.data?.data}
                ></FreeShareTable>
              </Center>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Box></Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalCloseButton /> */}
          <ModalHeader>Are you sure you went to Approve?</ModalHeader>

          <ModalBody>
            <FormControl zIndex={50} py={2} textAlign={"left"}>
              <FormLabel>
                {/* available Share Amount:{" "}
                {getAllowedsher.data?.data?.data.share_amount} */}
              </FormLabel>
              {/* <Select
                styles={{ textAlign: "left" }}
                w={"20vh"}
                // id={"nameid"}
                placeholder="select"
                getOptionLabel={(options) => options.title}
                getOptionValue={(options) => options.id}
                value={selectedOption}
                onChange={(newSelection) => {
                  console.log(newSelection.id);
                  handleChange(newSelection);
                }}
                options={options}
              /> */}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={ApproveShare}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
