import {
  Box,
  Button,
  FormControl,
  Heading,
  Stack,
  useToast,
  SimpleGrid,
  Wrap,
  WrapItem,
  Center,
  Flex,
  FormLabel,
} from "@chakra-ui/react";
import React from "react";
import FormComp from "../../utilities/components/FormComp";
import * as Yup from "yup";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { Formik } from "formik";
import Shareholder from "./Shareholder";
export default function CreateStakeholder() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const sitesMutation = useMutation(
    async (newData) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "stakeholders",
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const sitesMutationHandler = async (values) => {
    const {
      phone,
      status,
      dividend,
      capital,
      first_name,
      middle_name,
      last_name,
      email,
      gender,
      address,
      id_number,
      first_name_amharic,
      middle_name_amharic,
      last_name_amharic,
      address_amharic,
    } = values;
    // try {
    sitesMutation.mutate(
      {
        phone,
        status,
        dividend,
        capital,
        first_name,
        middle_name,
        last_name,
        email,
        gender: gender.value,
        address,
        id_number,
        first_name_amharic,
        middle_name_amharic,
        last_name_amharic,
        address_amharic,
      },
      {
        onSuccess: (responseData) => {
          toast({
            title: "Stakeholder created successfully",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          navigate("/Stake-holder");
        },
        onError: () => {
          toast({
            title: "Stakeholder not created.",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        },
      }
    );
    // } catch (err) {
    //   toast({
    //     title: "Error when Creating",
    //     status: "error",
    //     duration: 2500,
    //     isClosable: true,
    //   });
    // }
  };

  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"95%"}>
        <Heading> Create Shareholder</Heading>
        <Formik
          initialValues={{
            capital: "",
            dividend: "",
            phone: "",
            status: false,
            email: "",
          }}
          validationSchema={Yup.object({
            first_name: Yup.string().required("Required !"),
            middle_name: Yup.string().required("Required !"),
            last_name: Yup.string().required("Required !"),
            capital: Yup.string().required("Required !"),
            phone: Yup.string().required("Required !"),
            status: Yup.bool().required("Required !"),
            email: Yup.string().email("Invalid email"),
            gender: Yup.object(),
            address: Yup.string().required("Required !"),
            id_number: Yup.string().required("Required !"),
            first_name_amharic: Yup.string().required("required !"),
            middle_name_amharic: Yup.string().required("required !"),
            last_name_amharic: Yup.string().required("required !"),
            address_amharic: Yup.string().required("required !"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            sitesMutationHandler(values);
            console.log({ values });
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log({ formik });
            return (
              <form onSubmit={formik.handleSubmit}>
                <SimpleGrid
                  columns={2}
                  gap={6}
                  spacing={6}
                  alignContent={"center"}
                  justifyContent={"center"}
                >
                  <WrapItem
                    sx={{
                      // width: "45%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      shadow: "lg",
                      borderRadius: "10px",

                      p: 5,
                    }}
                  >
                    <Center mb={5}>
                      <Heading>Personal Information</Heading>
                    </Center>
                    <FormComp
                      id={"first_name"}
                      label={"First Name"}
                      formik={formik}
                    />
                    <FormComp
                      id={"first_name_amharic"}
                      label={"ስም"}
                      formik={formik}
                    />
                    <FormComp
                      id={"middle_name"}
                      label={"Middle Name"}
                      formik={formik}
                    />
                    <FormComp
                      id={"middle_name_amharic"}
                      label={"አባት ስም"}
                      formik={formik}
                    />
                    <FormComp
                      id={"last_name"}
                      label={"Last Name"}
                      formik={formik}
                    />
                    <FormComp
                      id={"last_name_amharic"}
                      label={"የአያት ስም"}
                      formik={formik}
                    />
                  </WrapItem>
                  <WrapItem
                    sx={{
                      // width: "45%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      shadow: "lg",
                      borderRadius: "10px",
                      p: 5,
                    }}
                  >
                    <Center mb={5}>
                      <Heading>Additional Information</Heading>
                    </Center>
                    <FormComp id={"phone"} label={"Phone"} formik={formik} />
                    <FormComp id={"email"} label={"Email"} formik={formik} />
                    <FormComp
                      id={"address"}
                      label={"Address"}
                      formik={formik}
                    />
                    <FormComp
                      id={"address_amharic"}
                      label={"አድራሻ"}
                      formik={formik}
                    />
                    <FormComp
                      id={"id_number"}
                      label={"ID Number"}
                      formik={formik}
                    />
                    <FormControl
                      sx={{
                        // width: "70%",
                        mx: "auto",
                        justifyContent: "center",
                      }}
                      py={2}
                      textAlign={"center"}
                    >
                      <FormLabel sx={{ textAlign: "left", fontWeight: "bold" }}>
                        Gender
                      </FormLabel>

                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderRadius: "5px",
                            width: "100%",
                            height: "5vh",
                            boxShadow: "none",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? "white" : "#B76A1D",
                            background: state.isSelected ? "#B76A1D" : "white",
                            padding: 10,
                          }),
                          container: (provided, state) => ({
                            ...provided,
                          }),
                        }}
                        w={"100%"}
                        // id={"nameid"}
                        placeholder="Select gender"
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={formik.values.gender}
                        options={[
                          {
                            value: "male",
                            label: "Male",
                          },
                          {
                            value: "female",
                            label: "Female",
                          },
                        ]}
                        onChange={(value) =>
                          formik.setFieldValue("gender", value)
                        }
                      />
                    </FormControl>{" "}
                  </WrapItem>
                  <WrapItem
                    sx={{
                      // width: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                      display: "flex",
                      flexDirection: "column",
                      shadow: "lg",
                      p: 5,
                    }}
                  >
                    <Center mb={5}>
                      <Heading>Share Information</Heading>
                    </Center>
                    <FormComp
                      id={"capital"}
                      label={"Capital(birr)"}
                      formik={formik}
                      type={"number"}
                    />
                    <FormComp
                      id={"dividend"}
                      label={"dividend(birr)"}
                      formik={formik}
                      type={"number"}
                    />
                  </WrapItem>
                </SimpleGrid>
                <Button
                  size="lg"
                  disabled={formik.isSubmitting || !formik.isValid}
                  mt={8}
                  type="submit"
                  bg={"#B76A1D"}
                  color={"white"}
                  variant="solid"
                  isLoading={sitesMutation.isLoading}
                >
                  Submit
                </Button>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Stack>
  );
}
