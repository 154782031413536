import {
  Box,
  Center,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";

import { FormControl } from "@chakra-ui/form-control";
import { SearchIcon } from "@chakra-ui/icons";
import { useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import { useAuth } from "../../utilities/hooks/auth-hook";

export default function Report() {
  const { token } = useAuth();
  const [profitedate, setprofitedate] = React.useState(new Date());
  const [selectedIndex, setselectedIndex] = useState(0);
  const [selectedT, setselectedT] = useState(0);
  const [selectedC, setselectedC] = useState(0);
  const [Data, setData] = React.useState(null);
  const [Alldata, setAlldata] = useState([]);
  const [query, setquery] = useState("");
  const [budgetYear, setBudgetYear] = useState(null);
  const queryClient = useQueryClient();

  const options = [
    {
      value: "All",
      label: "All",
    },
    { value: "Influential", label: "Influential" },
    { value: "Non Influential", label: " Non Influential" },
  ];
  const [filter, setFilter] = React.useState(options[0]);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getProfits = useQuery(
    "getProfitYear",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}profits`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        setBudgetYear(res.data.data[0].budget_year);
        // queryClient.invalidateQueries("getReportTable");
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const getCaptitalG = useQuery(
    "getCaptitalG",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}capital-growths`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        setData(res?.data?.data[0]?.model);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const getReportTable = useQuery(
    "getReportTable",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}stakeholder-report?model=${Data}&budjetYear=${budgetYear}&type=${filter.value}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: false,
      onSuccess: (res) => {
        setAlldata(res.data.data.stakeholders?.data);
        var dataAdp = 0;
        var Transferdata = 0;
        var cashout = 0;
        for (var i = 0; i < res.data.data.stakeholders.length; i++) {
          if (dataAdp <= res.data.data.stakeholders?.data[i]?.payments.length) {
            dataAdp = res.data.data.stakeholders?.data[i]?.payments.length;
            setselectedIndex(i);
            console.log(i);
          }
          if (
            Transferdata < res.data.data.stakeholders?.data[i]?.transfers.length
          ) {
            Transferdata = res.data.data.stakeholders?.data[i].transfers.length;
            setselectedT(i);
          }
          if (cashout < res.data.data.stakeholders?.data[i]?.cashout.length) {
            cashout = res.data.data.stakeholders?.data[i]?.cashout.length;
            setselectedC(i);
          }
        }
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  React.useEffect(() => {
    if (
      getCaptitalG.isSuccess &&
      getProfits.isSuccess &&
      profitedate !== null &&
      budgetYear !== null &&
      Data !== null
    ) {
      getReportTable.refetch();
    }
  }, [
    getCaptitalG.isSuccess,
    getProfits.isSuccess,
    Data,
    profitedate,
    budgetYear,
    getReportTable,
  ]);

  console.log(filter.value, "filter", Data, "data", budgetYear, "budgetYear");

  return (
    <Box padding={3}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          mb: 5,
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <InputGroup
          sx={{
            width: "50%",
          }}
        >
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            type="text"
            width={"60%"}
            placeholder="Search by Name"
            onChange={(e) => setquery(e.target.value)}
          />
        </InputGroup>
        <Box
          sx={{
            width: "40%",
            mx: 2,
          }}
        >
          <FormControl
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
            py={2}
            textAlign={"flex-start"}
          >
            <Text
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "60%",
              }}
            >
              Select Stakeholder Type
            </Text>
            <Select
              defaultValue={filter}
              styles={{
                menuPortal: (base) => ({
                  zIndex: 999999,
                }),
                menu: (provided) => ({ ...provided, zIndex: 999999 }),
                container: (provided, state) => ({
                  ...provided,
                  zIndex: 999999,
                  width: "60%",
                }),
              }}
              placeholder=" Filter Stakeholder"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(newSelection) => {
                setFilter(newSelection);
              }}
              options={options}
            />
          </FormControl>
        </Box>
      </Box>

      {getCaptitalG.isFetching ? (
        <Text>Loading....</Text>
      ) : !!Data ? (
        !!Data && getReportTable.isLoading ? (
          <Text>Loading....</Text>
        ) : (
          <div>
            <Box
              border={"1px solid rgba(0, 0, 0, 0.05)"}
              maxW={"100%"}
              overflowX={"scroll"}
              style={{
                overflowY: "scroll",
                height: "82vh",
              }}
            >
              {" "}
              <table
                style={{
                  border: "1px solid ",
                  width: "100%",
                  textAlign: "center",
                  color: "#B76A1D",
                }}
              >
                <thead
                  style={{
                    position: "sticky",
                    top: "0",
                    border: "2px solid ",
                    fontWeight: "bold",
                    background: "white",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      NO
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Name of Shareholders
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Payable
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Dividend
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Paid up Capital
                    </th>
                    {Alldata[0]?.capital_growth?.map((item) => {
                      return (
                        <th
                          style={{
                            border: "1px solid ",
                          }}
                        >
                          {" "}
                          <Text>{item?.share_growth_persont}%</Text>{" "}
                        </th>
                      );
                    })}{" "}
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Request Share
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Plowback
                    </th>
                    {Alldata[selectedIndex]?.payments?.map((item) => {
                      return (
                        <>
                          <th
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            <Center>Additional Payment </Center>
                            <Box height={0.3} w={"100%"} bg={"black"}></Box>
                            <HStack>
                              <td>
                                {" "}
                                <Text w={20}>Amount</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>Date</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>ACRV</Text>{" "}
                              </td>
                            </HStack>
                          </th>
                        </>
                      );
                    })}
                    {Alldata[selectedT]?.transfers?.map((item) => {
                      return (
                        <>
                          <th
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            <Center>Transfer </Center>
                            <Box height={0.3} w={"100%"} bg={"black"}></Box>
                            <HStack>
                              <td>
                                {" "}
                                <Text w={20}>Amount</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>to</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>Date</Text>{" "}
                              </td>
                            </HStack>
                          </th>
                        </>
                      );
                    })}
                    {Alldata[selectedC]?.cashout?.map((item) => {
                      return (
                        <>
                          <th
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            <Center>ACPV </Center>
                            <Box height={0.3} w={"100%"} bg={"black"}></Box>
                            <HStack>
                              <td>
                                {" "}
                                <Text w={20}>Payment</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>10%</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>ACPV</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>Date</Text>{" "}
                              </td>
                            </HStack>
                          </th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {Alldata?.filter((item) => item?.name?.includes(query))?.map(
                    (item, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            textAlign: "center",
                            border: "1px solid ",
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid ",
                              // position: "sticky",
                              // left: "0",
                              // background: "white",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              border: "1px solid ",
                              // position: "sticky",
                              // left: "0",
                              // background: "white",
                            }}
                          >
                            {item?.name}
                          </td>
                          <td
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            {item?.payable}
                          </td>
                          <td
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            {item?.dividend}{" "}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid ",
                            }}
                          >
                            {item?.current_capital}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid ",
                            }}
                          >
                            {item?.plowback}
                          </td>
                          {item?.capital_growth.map((data) => {
                            return (
                              <td
                                style={{
                                  border: "1px solid ",
                                }}
                              >
                                {" "}
                                <Text w={20}>{data.share_growth}</Text>{" "}
                              </td>
                            );
                          })}
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid ",
                            }}
                          >
                            {item?.request_share_amount}
                          </td>
                          {item?.payments?.length !== 0 ? (
                            <>
                              {item?.payments?.map((data) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.money_amount}
                                          </Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.additional_payment_date}
                                          </Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.acrv_no}
                                          </Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                              {/* {[
                                ...Array(
                                  Alldata[selectedIndex]?.payments?.length -
                                    item?.payments.length
                                ).keys(),
                              ].map((item) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })} */}
                            </>
                          ) : (
                            Alldata[selectedIndex]?.payments?.map((item) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      border: "1px solid ",
                                    }}
                                  >
                                    <HStack>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                    </HStack>
                                  </td>
                                </>
                              );
                            })
                          )}

                          {item?.transfers?.length !== 0 ? (
                            <>
                              {item?.transfers.map((data) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.money_amount}
                                          </Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          <Text w={20}>{data.to.name}</Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.transfer_date}
                                          </Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                              {/* {[
                                ...Array(
                                  Alldata[selectedT]?.transfers?.length -
                                    item?.transfers?.length
                                ).keys(),
                              ]?.map((item) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })} */}
                            </>
                          ) : (
                            Alldata[selectedT]?.transfers?.map((item) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      border: "1px solid ",
                                    }}
                                  >
                                    <HStack>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                    </HStack>
                                  </td>
                                </>
                              );
                            })
                          )}
                          {item?.cashout?.length !== 0 ? (
                            <>
                              {item?.cashout?.map((data) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data?.new_money_amount}
                                          </Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          <Text w={20}>{data?.tax}</Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          <Text w={20}>{data?.acpv_no}</Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data?.cashout_date}
                                          </Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                              {[
                                ...Array(
                                  Alldata[selectedC]?.cashout?.length -
                                    item?.cashout?.length
                                ).keys(),
                              ]?.map((item) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            Alldata[selectedC]?.cashout?.map((item) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      border: "1px solid ",
                                    }}
                                  >
                                    <HStack>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                    </HStack>
                                  </td>
                                </>
                              );
                            })
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Box>
            {/* </ScrollMenu> */}
          </div>
        )
      ) : (
        <Text>Create Share growths first</Text>
      )}
      {/* <Tabs>
        <TabList>
          <Tab>Dividends</Tab>
          <Tab>Payments</Tab>
          <Tab>Shares</Tab>
          <Tab>Transfers</Tab>
          <Tab>text</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ReportTable column={columnsD} row={dataD} title="Dividends List" />
          </TabPanel>
          <TabPanel>
            <ReportTable column={columnsP} row={dataP} title="Payments List" />
          </TabPanel>
          <TabPanel>
            <ReportTable column={columnsS} row={dataS} title="Shares List" />
          </TabPanel>
          <TabPanel>
            <ReportTable column={columnsT} row={dataT} title="Transfers List" />
          </TabPanel>

          <TabPanel>
            <div>
              <table
                className={{
                  fontFamily: "arial, sans-serif",
                  borderCollapse: "collapse",
                  width: "100%",
                }}
              >
                <tr
                  className={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  <th
                    className={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    This{" "}
                  </th>
                  <th
                    className={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    Next{" "}
                  </th>
                </tr>
                <tr>
                  <td>This is where the table data goes</td>
                  <td>This is the second columns data</td>
                </tr>
              </table>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs> */}

      {/* <input type="file" id="input" onChange={onChange} />
      <Button onClick={ShareMutationHandler}>Share</Button> */}
    </Box>
  );
}
