import {
  Box,
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { TaxInfo } from "./components/Tax/Tax";
import { AgendaTemplate } from "./components/meetingTemplate/AgendaTemplate";
import { MeetingType } from "./components/meetingType/MeetingType";
import { TransfersAmounts } from "./components/tranferAmount/TransferAmunts";
const UtilityManagement = () => {
  const activeIndex = [1, 2].findIndex((option) => option.active);
  const [tabIndex, setTabIndex] = React.useState(
    activeIndex !== -1 ? activeIndex : 0
  );

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const tabStyle = {
    p: 2,
    px: 5,
    m: 2,
    _active: {
      border: "5px solid #E2E8F0",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  };

  return (
    <Box p="2%" w="100%">
      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        orientation="vertical"
        sx={{
          height: "100%",
        }}
      >
        <TabList
          py="0px"
          alignContent={"flex-start"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          sx={{
            borderBottom: "1px solid #E2E8F0",
            borderRadius: "10px",
            mb: "10px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            justifyContent: "center",
            flexWrap: { base: "wrap", md: "nowrap" },
            height: "100%",
            maxW: "210px",
          }}
        >
          <Tab
            py="6px"
            sx={{
              ...tabStyle,
            }}
          >
            <Button
              sx={{
                p: 2,
                width: "100%",
                justifyContent: "flex-start",
                m: 2,
                // borderBottom: "5px solid #E2E8F0",
                // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              Meeting Type
            </Button>
          </Tab>
          <Tab
            py="6px"
            sx={{
              ...tabStyle,
            }}
          >
            <Button
              sx={{
                p: 2,
                width: "100%",
                justifyContent: "flex-start",
                m: 2,
                // borderBottom: "5px solid #E2E8F0",
                // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              Agenda Template{" "}
            </Button>
          </Tab>
          <Tab
            py="6px"
            sx={{
              ...tabStyle,
            }}
          >
            <Button
              sx={{
                p: 2,
                width: "100%",
                justifyContent: "flex-start",
                m: 2,
                textAlign: "balance",
                whiteSpace: "wrap",
              }}
            >
              Manage Minimum Transfer Amount
            </Button>
          </Tab>
          {/* <Tab
            py="6px"
            sx={{
              ...tabStyle,
            }}
          >
            <Button
              sx={{
                p: 2,
                width: "100%",
                justifyContent: "flex-start",
                m: 2,
                // borderBottom: "5px solid #E2E8F0",
                // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              Manage Tax
            </Button>
          </Tab> */}
        </TabList>{" "}
        <TabPanels>
          <TabPanels justifyContent={"center"}>
            <TabPanel p="0px">
              <Stack direction="column" spacing={4}>
                <Text
                  sx={{
                    borderBottom: "1px solid #E2E8F0",
                    borderRadius: "10px",
                    mb: "10px",
                    color: "#4676A8",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                  textAlign={"center"}
                  tabIndex={tabIndex}
                >
                  Meeting Type
                </Text>
                <MeetingType tabIndex={tabIndex} />
              </Stack>
            </TabPanel>{" "}
            <TabPanel p="0px">
              <Stack direction="column" spacing={4}>
                <Text
                  sx={{
                    borderBottom: "1px solid #E2E8F0",
                    borderRadius: "10px",
                    mb: "10px",
                    color: "#4676A8",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                  textAlign={"center"}
                  tabIndex={tabIndex}
                >
                  {" "}
                  Agenda Template
                </Text>
                <AgendaTemplate />{" "}
              </Stack>
            </TabPanel>
            <TabPanel p="0px">
              <Stack direction="column" spacing={4}>
                <Text
                  sx={{
                    borderBottom: "1px solid #E2E8F0",
                    borderRadius: "10px",
                    mb: "10px",
                    color: "#4676A8",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                  textAlign={"center"}
                  tabIndex={tabIndex}
                >
                  Minimum Transfer Amount
                </Text>
                <TransfersAmounts />
              </Stack>
            </TabPanel>{" "}
            <TabPanel p="0px">
              <Stack direction="column" spacing={4}>
                <Text
                  sx={{
                    borderBottom: "1px solid #E2E8F0",
                    borderRadius: "10px",
                    mb: "10px",
                    color: "#4676A8",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                  textAlign={"center"}
                  tabIndex={tabIndex}
                >
                  Tax Information
                </Text>
                <TaxInfo />
              </Stack>
            </TabPanel>{" "}
          </TabPanels>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default UtilityManagement;
