import { Box } from "@chakra-ui/react";
import { Form } from "formik";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  list-style: none;
  box-shadow: ${({ noshadow }) =>
    noshadow === "true" ? "none" : "0 0px 8px rgba(0, 0, 0, 0.26)"};
  border-radius: 6px;
  padding: 0 70px;
  background: white;
  width: ${({ fromLesson }) => (fromLesson ? "70%" : "50%")};
  fromLesson @media (max-width: 1280px) {
    margin-top: 0.5rem;
    padding: 0 50px;
  }
  @media (max-width: 992px) {
    padding: 0 40px;
  }
  @media (max-width: 600px) {
    /* padding: 0 35px; */
    width: 450px;
  }
  @media (max-width: 480px) {
    width: full;
    /* padding: 0 30px; */
  }
`;
export const CheckContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 70%;
  @media screen and (max-width: 760px) {
    width: 100%;
  }
`;

export const MobileIcon = styled(Box)`
  display: none;

  @media screen and (max-width: 992px) {
    display: flex;
    cursor: pointer;
  }
`;
