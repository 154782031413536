import {
  Box,
  Button,
  HStack,
  Progress,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Loader from "react-spinners/BarLoader";
import { useAuth } from "../../../../utilities/hooks/auth-hook";
import AddAgendaTemplate from "./AddAgendaTemplateModal";
import UpdateAgendaTemplate from "./UpdateAgendaTemplate";

export const AgendaTemplate = () => {
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editId, setEditId] = useState(false);
  const toast = useToast();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const deleteTyreCondition = useMutation(
    async (deleteServiceType) =>
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL +
          `agenda-templates/${deleteServiceType?.id}`,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const getAgendaTemplates = useQuery(
    ["agenda-template"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}agenda-templates`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );

  React.useEffect(() => {
    if (getAgendaTemplates?.data?.data) {
      setData(getAgendaTemplates?.data?.data?.data);
    }
  }, [getAgendaTemplates?.data?.data]);
  const queryClient = useQueryClient();
  const [columns, setColumns] = React.useState([
    { title: "Title", field: "title" },
    {
      title: "Description",
      field: "description",
    },

    {
      render: (rowData) => {
        return (
          <HStack>
            <Button
              // disabled={tyreConditions?.isFetching ? true : false}
              size={"sm"}
              onClick={() => {
                setEditId(rowData?.id);
              }}
            >
              <AiFillEdit />
            </Button>
            <Box>
              <Button
                // disabled={tyreConditions?.isFetching ? true : false}
                size={"sm"}
                border="1px solid #006ddb"
                onClick={() => {
                  deleteTyreCondition.mutate(
                    {
                      id: rowData?.id,
                    },
                    {
                      onSuccess: (responseData) => {
                        toast({
                          title: " deleted successfully",
                          status: "success",
                          duration: 5000,
                          isClosable: true,
                        });
                        queryClient.invalidateQueries("tyres");
                      },
                      onError: (responseData) => {
                        toast({
                          title:
                            responseData?.response?.data?.message ||
                            "Not  deleted!",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                      },
                    }
                  );
                }}
              >
                <AiOutlineDelete />
              </Button>
            </Box>
            {deleteTyreCondition?.isLoading && <Loader />}
          </HStack>
        );
      },
    },
  ]);
  const onCancel = () => {
    setEditId(false);
    onClose();
    onMeetingClose();
  };
  const {
    isOpen: isMeetingOpen,
    onOpen: onMeetingOpen,
    onClose: onMeetingClose,
  } = useDisclosure();

  return (
    <>
      <UpdateAgendaTemplate
        editId={editId}
        isOpen={!!editId}
        onClose={onCancel}
      />
      <AddAgendaTemplate isOpen={isMeetingOpen} onClose={onCancel} />

      <Box w={"100%"}>
        <Button my={2} onClick={onMeetingOpen}>
          Add Agenda Template
        </Button>
        <MaterialTable
          isLoading={getAgendaTemplates.isLoading}
          components={{
            OverlayLoading: (props) => (
              <Progress size="md" colorScheme="pink" isIndeterminate />
            ),
          }}
          title=" Agenda Template"
          columns={columns}
          data={getAgendaTemplates?.data?.data?.data}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#E82481",
              color: "#FFF",
            },
          }}
        />
      </Box>
    </>
  );
};
