import { Stack, VStack } from "@chakra-ui/react";
import React from "react";
// import LogoComponent from "../utilities/components/LogoComponent";
import { Link } from "react-router-dom";
import { Logo } from "../../utilities/components/Logo";
import { StoreManagerRoutes } from "../Data";
import SideButton from "./SideButton";

export default function SideBar() {
  //Jsx
  return (
    <Stack
      shadow={"sm"}
      minH={"100vh"}
      px={"5"}
      py={"5"}
      direction={"column"}
      bg={"#080808"}
      minW={{ base: "100%", lg: "25%", xl: "21%", "2xl": "17%" }}
      maxW={{ base: "100%", lg: "25%", xl: "21%", "2xl": "17%" }}
    >
      <Link to="/">
        <Logo />
      </Link>
      <VStack pt={8} alignItems={"flex-start"} spacing={3}>
        {StoreManagerRoutes.map((adminRoute) => (
          <SideButton to={adminRoute?.to} name={adminRoute?.name}>
            {adminRoute?.icon}
          </SideButton>
        ))}
      </VStack>
    </Stack>
  );
}
