import {
  Box,
  Center,
  Text,
  Stack,
  List,
  ListItem,
  ListIcon,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

export const NomineeCard = ({
  name,
  capital,
  boughtShareCount,
  numberOfShare,
  dividend,
  soldShareCount,
  onClick,
  disabled,
  viewOnly,
}) => {
  console.log("capital", capital);
  return (
    <Center py={6}>
      <Box
        function
        minW={"330px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"md"}
        overflow={"hidden"}
      >
        <Stack
          textAlign={"center"}
          p={6}
          color={useColorModeValue("gray.800", "white")}
          align={"center"}
        >
          <Text
            fontSize={"sm"}
            fontWeight={500}
            bg={useColorModeValue("green.50", "green.900")}
            p={2}
            px={3}
            color={"green.500"}
            rounded={"full"}
          >
            {name}
          </Text>
          <Text
            fontSize={"sm"}
            fontWeight={900}
            bg={useColorModeValue("green.50", "green.900")}
            p={2}
            px={3}
            color={"green.300"}
            rounded={"full"}
          >
            Capital
          </Text>
          <Stack direction={"row"} align={"center"} justify={"center"}>
            <Text fontSize={"2xl"} fontWeight={800}>
              {/* {capital.toLocaleString()} ETB */}
              {capital.toLocaleString()}
              {" ETB"}
            </Text>
            {/* <Text color={"gray.500"}>/month</Text> */}
          </Stack>
        </Stack>

        <Box
          bg={useColorModeValue("gray.50", "gray.900")}
          px={6}
          py={10}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <List
            spacing={3}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
          >
            {/* <ListItem>
              <ListIcon as={CheckIcon} color="green.400" />
              {boughtShareCount} bought shares
            </ListItem> */}
            <ListItem>
              <ListIcon as={CheckIcon} color="green.400" />
              {numberOfShare} shares
            </ListItem>
            {/* <ListItem>
              <ListIcon as={CheckIcon} color="green.400" />
              {dividend} Dividends
            </ListItem>
            <ListItem>
              <ListIcon as={CheckIcon} color="green.400" />
              {soldShareCount} sold shares
            </ListItem> */}
          </List>
          {!viewOnly && (
            <Button
              mt={10}
              w={"full"}
              bg={"green.400"}
              color={"white"}
              rounded={"xl"}
              boxShadow={"0 5px 20px 0px rgb(72 187 120 / 43%)"}
              _hover={{
                bg: "green.500",
              }}
              _focus={{
                bg: "green.500",
              }}
              onClick={onClick}
              disabled={disabled}
            >
              Make Nominee
            </Button>
          )}
        </Box>
      </Box>
    </Center>
  );
};
