import { Box, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";

import React from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../utilities/hooks/auth-hook";

export default function Editepage() {
  const { useState } = React;
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getEditData = useQuery(
    "getEditData",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}stakeholder-share-detail/${userId}?profit_id=${profitId}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data?.data?.shares);
        setDataT(res?.data?.data?.transfers);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const EditShareMutation = useMutation(
    async (newData) =>
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + "shares/" + newData?.id,
        newData,
        {
          headers,
        }
      ),

    {
      retry: false,
    }
  );
  const EditShareMutationHandler = async (values) => {
    const { share_amount, id, av_no, plowback_created_date, from_payable } =
      values;
    console.log({ values });
    try {
      EditShareMutation.mutate(
        {
          id: id,
          share_amount:
            share_amount % 500 === 0
              ? share_amount
              : share_amount - (share_amount % 500),
          plowback_created_date: plowback_created_date,
          av_no: av_no,
          from_payable:
            share_amount % 500 === 0
              ? from_payable
              : from_payable + (share_amount % 500),
          // stakeholder_id: selectedOption.id,
          // year: year,
          // payable: payable,
          // total_payable: total_payable,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: "Edited successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            getEditData.refetch();
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: "Dividend not edited.",
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const TransfersMutation = useMutation(
    async (newData) =>
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + "transfers/" + newData?.id,
        newData,
        {
          headers,
        }
      ),

    {
      retry: false,
    }
  );
  const TransfersMutationHandler = async (values) => {
    const {
      money_amount,
      id,
      transfer_date,

      from_payable,
    } = values;
    console.log({ values });
    try {
      TransfersMutation.mutate(
        {
          id: id,
          transfer_amount:
            money_amount % 500 === 0
              ? money_amount
              : money_amount - (money_amount % 500),
          transfer_date: transfer_date,

          from_payable:
            money_amount % 500 === 0
              ? from_payable
              : from_payable + (money_amount % 500),
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: "Edited successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            getEditData.refetch();
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: "Dividend not edited.",
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const [columns, setColumns] = useState([
    { title: "Date", field: "plowback_created_date", editable: "onUpdate" },
    { title: "Share_amount", field: "share_amount", editable: "onUpdate" },
    { title: "AV_Number", field: "av_no", editable: "onUpdate" },
    { title: "From_payable", field: "from_payable", editable: "onUpdate" },
    { title: "Tax", field: "tax", editable: "never" },
  ]);
  const [columnsT, setColumnsT] = useState([
    { title: "Date", field: "transfer_date", editable: "onUpdate" },
    { title: "Transfered_amount", field: "money_amount", editable: "onUpdate" },
    { title: "To", field: "to.name", editable: "never" },
  ]);

  const [data, setData] = useState([]);
  const [dataT, setDataT] = useState([]);
  console.log({ data });
  const {
    state: { userId, name, profitId },
  } = useLocation();
  return (
    <Box textAlign={"left"}>
      <Text fontSize="3xl" ml={5} mt={10} mb={5}>
        {" "}
        Your Are About to Edit {name} Transition
      </Text>
      <Box ml={5} w={"95%"}>
        <MaterialTable
          title="Edit Plowback Shares"
          columns={columns}
          data={data}
          options={{
            filtering: true,

            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#E82481",
              color: "#FFF",
            },
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  // const dataUpdate = [...data];
                  // const index = oldData.tableData.id;
                  // dataUpdate[index] = newData;
                  // setData([...dataUpdate]);

                  resolve();
                }, 1000);
                EditShareMutationHandler(newData);
                console.log({ newData });
              }),
          }}
        />
      </Box>
      <Box ml={5} mt={10} w={"95%"}>
        <MaterialTable
          title="Edit Transfers"
          columns={columnsT}
          data={dataT}
          options={{
            filtering: true,

            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#E82481",
              color: "#FFF",
            },
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve();
                }, 1000);
                TransfersMutationHandler(newData);
                console.log({ newData });
              }),
          }}
        />
      </Box>
    </Box>
  );
}
