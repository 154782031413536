import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Loader from "../../../assets/Loader";
import { useAuth } from "../../../utilities/hooks/auth-hook";

export default function AverageTable() {
  const { useState } = React;
  const [selectedOption, setselectedOption] = React.useState([]);

  const [options, setoptions] = React.useState([]);

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setselectedOption(selectedOption);
    // getDividends.refetch();
  };

  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [profit, setProfite] = useState(null);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getAverages = useQuery(
    `getDividends ${selectedOption.id}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}individual-average/${selectedOption.id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log("test", res.data.data);
        setData(res.data.data?.stakeholders);
        setProfite(res?.data?.data?.profit?.money_amount);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getDividends: getAverages });
  const [columns, setColumns] = useState([
    { title: "Name", field: "name" },

    {
      title: "Individual Average ",
      field: "averages.individualAverage",

      render: (data) => {
        return `${Number(data?.averages?.individualAverage).toLocaleString(
          "en-GB",
          {
            maximumFractionDigits: 2,
          }
        )} ETB`;
      },
    },
    // {
    //   title: "Average Paid-up",
    //   field: "averages.paid_up",
    //   render: (data) => {
    //     return `${Number(data?.averages?.paid_up).toLocaleString("en-GB", {
    //       maximumFractionDigits: 2,
    //     })} ETB`;
    //   },
    // },

    {
      title: "Current Capital ",
      render: (data) => {
        return `${Number(data?.current_capital).toLocaleString("en-GB", {
          maximumFractionDigits: 2,
        })} ETB`;
      },
    },

    // {
    //   title: "Net Dividend ",
    //   field: "dividend",
    //   render: (amount) => `${Number(amount?.dividend).toLocaleString()}`,
    // },
  ]);

  const getyear = useQuery(
    "getyear",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}finalized-profits`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions([]);
        setProfite(res?.data?.data?.profit?.money_amount);

        for (let i = 0; i < res.data.data.length; i++) {
          console.log(i);

          setoptions((prevOpt) =>
            prevOpt.concat({
              title: `${res.data.data[i].start_date} - ${res.data.data[i].end_date} `,
              id: `${res.data.data[i].id}`,
            })
          );
        }

        // setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  return (
    <Box w={"100%"}>
      <HStack
        spacing={8}
        sx={{
          // border: "1px solid red",
          justifyContent: "space-around",
        }}
      >
        <Box
          align={"left"}
          justifyContent={"left"}
          // borderWidth={"2px"}
          w={"34vh"}
          paddingLeft={"10px"}
        >
          <FormControl zIndex={50} py={2} textAlign={"left"}>
            <FormLabel fontWeight={"bolder"}>Select Year</FormLabel>
            <Select
              styles={{ textAlign: "left" }}
              w={"20vh"}
              // id={"nameid"}
              placeholder="select"
              getOptionLabel={(options) => options.title}
              getOptionValue={(options) => options.id}
              value={selectedOption}
              onChange={(newSelection) => {
                console.log(newSelection);
                handleChange(newSelection);
              }}
              options={options}
            />
          </FormControl>
        </Box>
        {selectedOption?.length !== 0 && (
          <Text fontWeight={"bolder"} justifyContent={"end"}>
            Total Profit: {Number(profit).toLocaleString("en-US")} ETB
          </Text>
        )}
      </HStack>
      {getAverages?.isFetching ? (
        <Loader />
      ) : (
        <Stack>
          <MaterialTable
            title="Average List"
            columns={columns}
            data={data || []}
            options={{
              pageSizeOptions: [10, 15, 20, 30, 50, 75, 100],
              pageSize: 20,
              filtering: true,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#E82481",
                color: "#FFF",
              },
            }}
            detailPanel={(rowData) => {
              return (
                <Box>
                  <MaterialTable
                    title="Individual Average"
                    columns={[
                      {
                        title: "Created At",
                        field: "created_date",
                      },
                      {
                        title: "Individual Average",
                        field: "individual",
                        render: (amount) =>
                          `${Number(amount?.individual).toLocaleString(
                            "en-GB",
                            {
                              maximumFractionDigits: 2,
                            }
                          )} ETB`,
                      },
                      {
                        title: "Current Capital",
                        field: "current_capital",
                        render: (amount) =>
                          `${Number(amount?.current_capital).toLocaleString(
                            "en-GB",
                            {
                              maximumFractionDigits: 2,
                            }
                          )} ETB`,
                      },
                      { title: "Day Differences", field: "difference_days" },
                      {
                        title: "Type",
                        field: "type",
                      },
                      {
                        title: "Details",
                        field: "Details",
                        render: (data) => {
                          return (
                            <Tooltip
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    p: 4,
                                    // minWidth: "500px",
                                    minHeight: "100px",
                                    boxShadow: "0 0 1px rgba(0,0,0,0.5)",
                                    textAlign: "center",
                                  }}
                                >
                                  {/* {" "}
                                  <Text
                                    sx={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    day difference is days between share <br />{" "}
                                    or transfer created_at and profit end date
                                  </Text> */}

                                  <Text>
                                    ({data?.difference_days} X{" "}
                                    {data?.current_capital}
                                    )/365 ={" "}
                                    {`${Number(data?.individual).toLocaleString(
                                      "en-GB",
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )} ETB`}
                                  </Text>
                                  <Text
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "1rem",
                                      color: "red",
                                    }}
                                  >
                                    IV = current capital{" "}
                                    <span style={{ color: "green" }}>X</span>{" "}
                                    difference date / 365
                                  </Text>
                                </Box>
                              }
                              aria-label="A tooltip"
                            >
                              <InfoIcon />
                            </Tooltip>
                          );
                        },
                      },
                    ]}
                    data={rowData?.averages?.individualAve || []}
                    options={{
                      pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                      pageSize: 5,
                      filtering: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: "gray",
                        color: "#FFF",
                      },

                      cellStyle: {
                        textAlign: "left",
                        // padding: "-3rem",
                        height: "1rem",
                      },
                    }}
                  />
                </Box>
              );
            }}
          />
        </Stack>
      )}
    </Box>
  );
}
