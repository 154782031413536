import {
  Box,
  Button,
  Checkbox,
  FormControl,
  HStack,
  Heading,
  Input,
  Stack,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import Select from "react-select";
import * as Yup from "yup";
import FormComp from "../../utilities/components/FormComp";

import { Text } from "@chakra-ui/layout";
import axios from "axios";
import { Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { NomineeCard } from "../components/NomineeCard";
export default function Createpayments() {
  const [selectedOption, setselectedOption] = React.useState([]);
  const [dividend, setdividend] = React.useState(0);
  const [options, setoptions] = React.useState([]);
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const {
    state: { state, isReadOnly, isEdit, isShow },
  } = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const PaymentMutation = useMutation(
    async (newData) =>
      await axios.post(process.env.REACT_APP_BACKEND_URL + "cashout", newData, {
        headers,
      }),
    {
      retry: false,
    }
  );
  const PaymentMutationHandler = async (values) => {
    const { amount, invoice_number, prommised } = values;
    console.log({ selectedOption });
    try {
      PaymentMutation.mutate(
        {
          stakeholder_id: selectedOption.id,
          amount: amount,
          invoice_no: invoice_number,
          prommised: prommised,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: "payment created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/payments");
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: "payment not created.",
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const getStakeHoldername = useQuery(
    "getStakeHoldername",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getStakeHoldername });
  console.log("test", { selectedOption });
  React.useEffect(() => {
    if (selectedOption?.dividend) {
      setdividend(selectedOption?.dividend);
    }
  }, [dividend]);
  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
      justifySelf={"center"}
    >
      <HStack w={"100%"} justify={"center"} align={"center"}>
        <Box w={"60%"} justify={"center"} align={"center"}>
          <Heading mb={"40px"}>
            {" "}
            {isEdit ? "Payment" : isShow ? "Payment" : "Payment Checkout"}
          </Heading>
          <Formik
            initialValues={{
              name: state !== null ? state.stakeholder.name : "",
              amount: state !== null ? state.amount : selectedOption?.dividend,
              invoice_number: state !== null ? state.invoice_number : "",
              promised: false,
              invoice_date: state !== null ? state.invoice_date : "",
            }}
            validationSchema={Yup.object({
              amount: Yup.number()
                .required("Required !")
                .max(selectedOption?.dividend),
              invoice_number: Yup.string().required("Required !"),
              invoice_date: Yup.date().required("Required !"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              PaymentMutationHandler(values);
              console.log({ values });
              setSubmitting(false);
            }}
          >
            {(formik) => {
              console.log({ formik });
              return (
                <form onSubmit={formik.handleSubmit}>
                  <VStack
                    spacing={4}
                    align={"center"}
                    justifyContent={"center"}
                    width={"50%"}
                  >
                    {isEdit ? (
                      <FormComp
                        id={"name"}
                        label={"Name"}
                        formik={formik}
                        isReadOnly={isReadOnly}
                      />
                    ) : isShow ? (
                      <FormComp
                        id={"name"}
                        label={"Name"}
                        formik={formik}
                        isReadOnly={isReadOnly}
                      />
                    ) : (
                      <FormControl
                        sx={{
                          width: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        py={2}
                        textAlign={"center"}
                      >
                        <Text
                          sx={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Select Stakeholder
                        </Text>
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderRadius: "5px",
                              width: "100%",
                              height: "5vh",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              color: state.isSelected ? "white" : "#B76A1D",
                              background: state.isSelected
                                ? "#B76A1D"
                                : "white",
                              padding: 10,
                            }),
                            container: (provided, state) => ({
                              ...provided,
                              width: "100%",
                            }),
                          }}
                          // id={"nameid"}
                          placeholder="select"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={selectedOption}
                          onChange={(newSelection) => {
                            console.log(newSelection.id);
                            // formik?.setFieldValue("nameid", newSelection.id);
                            handleChange(newSelection);
                            setdividend(newSelection.dividend);
                          }}
                          options={options}
                        />
                      </FormControl>
                    )}
                    <FormComp
                      id={"amount"}
                      label={"Amount Before Tax"}
                      formik={formik}
                      type={"number"}
                      values={dividend}
                    />{" "}
                    <FormComp
                      // id={"amount"}
                      label={"Payable "}
                      formik={formik}
                      type={"number"}
                      isReadOnly={true}
                      values={dividend}
                    />
                    <FormComp
                      id={"invoice_number"}
                      label={"Enter Invoice Number"}
                      formik={formik}
                    />
                    <FormComp
                      id={"invoice_date"}
                      label={"Enter Invoice DAte"}
                      formik={formik}
                      type={"date"}
                    />
                    <VStack
                      sx={{
                        width: "100%",
                        height: "20vh",
                        justifyContent: "space-around",
                        alignItems: "center",
                        my: "10px",
                        alignSelf: "center",
                        ml: 5,
                      }}
                    >
                      <FormControl
                        sx={{
                          shadow: "md",
                          width: "80%",
                          py: 3,
                        }}
                      >
                        <Text textAlign={"center"}>After Tax</Text>

                        <Input
                          label={"After Tax"}
                          placeholder={"After Tax"}
                          value={
                            Number(formik.values.amount) +
                            Number(formik.values.amount * 0.15)
                          }
                          disabled={true}
                          sx={{
                            "&:disabled": {
                              color: "red",
                              fontWeight: "bold",
                              bg: "white",
                              cursor: "not-allowed",
                              // width: "30%",
                              border: "none",
                              textAlign: "center",
                            },
                          }}
                        />
                      </FormControl>

                      <Checkbox
                        float={"left"}
                        id={"promised"}
                        value={formik.values.promised}
                        colorScheme="green"
                        onChange={(value) => {
                          formik.setFieldValue(
                            "promised",
                            !formik.values.promised
                          );
                        }}
                      >
                        is prommised
                      </Checkbox>
                    </VStack>
                    {!isShow && (
                      <Button
                        disabled={formik.isSubmitting}
                        mt={6}
                        type="submit"
                        bg={"#B76A1D"}
                        color={"white"}
                        variant="solid"
                        isReadOnly={isReadOnly}
                      >
                        Submit
                      </Button>
                    )}
                  </VStack>
                </form>
              );
            }}
          </Formik>
        </Box>
        {selectedOption?.id && (
          <NomineeCard
            name={selectedOption?.name}
            capital={selectedOption?.current_capital}
            boughtShareCount={selectedOption?.bought_shares_count}
            share={selectedOption?.shares}
            dividend={selectedOption?.dividend}
            soldShareCount={selectedOption?.sold_shares_count}
            viewOnly={true}
          />
        )}
      </HStack>
    </Stack>
  );
}
