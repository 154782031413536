import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

export default function FormCompamount({
  formik,
  id,
  label,
  isReadOnly,
  type,
  setFrompayable,
}) {
  // useEffect(() => {
  //   if (id == "amount") {
  //     console.log({ ...formik.getFieldProps(id).value });
  //   }
  // }, []);
  console.log({ id });
  return (
    <FormControl py={2}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <Input
        isReadOnly={isReadOnly}
        id={id}
        type={type ? type : "text"}
        size={"sm"}
        value={formik.values.amount}
        onChange={(e) => {
          formik?.setFieldValue(id, e.target.value);
          if (e.target.value % 500 === 0) {
            formik?.setFieldValue("amb", e.target.value);
            formik?.setFieldValue("frompayable", e.target.value % 500);
          } else {
            console.log(e.target.value % 500);
            formik?.setFieldValue("frompayable", e.target.value % 500);
            formik?.setFieldValue(
              "amb",
              e.target.value - (e.target.value % 500)
            );
          }
        }}
        // {...formik.getFieldProps(id)}
      />
      {formik?.values?.amb !== 0 && (
        <Text>
          {" "}
          {formik?.values?.amb} birr Share and From_Payable:
          {formik?.values?.frompayable} birr{" "}
        </Text>
      )}

      {formik.touched[id] && formik.errors[id] ? (
        <FormHelperText fontSize={"xs"} color={"red.600"}>
          {formik.errors[id]}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
