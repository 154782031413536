import {
  Box,
  Button,
  Center,
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Wrap,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";
import { CustomInput } from "../../../../utilities/components/TaxUpdate";
import { useAuth } from "../../../../utilities/hooks/auth-hook";
const AddAgendaTemplate = ({ isOpen, onClose }) => {
  const { token, logout } = useAuth();

  const toast = useToast();
  const queryclient = useQueryClient();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const addMeetingTemplate = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}agenda-templates`,
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {
    title: "",
    title_amharic: "",
    description_amharic: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Agenda title is required"),
    title_amharic: Yup.string(),
    description: Yup.string().required("Agenda description is required"),
    description_amharic: Yup.string(),
  });

  const onSubmit = async (values) => {
    try {
      addMeetingTemplate.mutate(
        {
          ...values,
        },
        {
          onSuccess: (responseData) => {
            onClose();
            toast({
              title: "Created successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            queryclient.invalidateQueries("agenda-template");
          },
          onError: (responseDataError) => {
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                " not created",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        <Center p={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(values) => {
              console.log(values);
              return (
                <Form>
                  <Box my={4}>
                    <Center
                      color="#AFAFAF"
                      fontSize="25px"
                      fontWeight="semibold"
                    >
                      Add Agenda Template
                    </Center>
                    <Divider color="#333" my={4} />
                  </Box>
                  <Wrap
                    fontSize="18px"
                    justify="center"
                    spacing={4}
                    direction={"column"}
                  >
                    <Field name="title">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title="Title"
                          htmlFor="title"
                          error={values.errors.title}
                          touched={values.touched.title}
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Wrap
                    justify="center"
                    spacing={4}
                    direction={"column"}
                    fontSize="18px"
                  >
                    <Field name="title_amharic">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title="አጀንዳ ርዕስ"
                          htmlFor="title_amharic"
                          error={values.errors.title_amharic}
                          touched={values.touched.title_amharic}
                        />
                      )}
                    </Field>
                  </Wrap>

                  <Wrap justify="center" spacing={4} direction={"column"}>
                    <Field name="description">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title="description"
                          htmlFor="description"
                          error={values.errors.description}
                          touched={values.touched.description}
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Wrap justify="center" spacing={4} direction={"column"}>
                    <Field name="description_amharic">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title="አጀንዳ መግለጫ"
                          htmlFor="description_amharic"
                          error={values.errors.description_amharic}
                          touched={values.touched.description_amharic}
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Center>
                    <Button
                      my={5}
                      colorScheme="facebook"
                      disabled={values.errors.name}
                      _hover={
                        values.isValid &&
                        values.dirty && {
                          opacity: "0.8",
                        }
                      }
                      type={
                        !addMeetingTemplate?.isLoading ? "submit" : "button"
                      }
                      isLoading={addMeetingTemplate?.isLoading}
                    >
                      Add
                    </Button>
                  </Center>
                </Form>
              );
            }}
          </Formik>
          {/* ) : (
              <Center>Check your connection and refresh </Center>
            )} */}
        </Center>
        {/* ) : (
          <Center alignSelf={"center"} minH="500px">
            <SyncLoader size={15} margin={2} color="#4962f2" />
          </Center>
        )} */}
      </ModalContent>
    </Modal>
  );
};

export default AddAgendaTemplate;
