import { Box, Button, Center, Stack } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Capitalgrowthtable from "./Table/Capitalgrowthtable";

export default function CapitalGrowth() {
  let navigate = useNavigate();
  const [isDesable, setisDesable] = useState(false);
  return (
    <Stack padding={10}>
      <Box>
        <Button
          mt={"20px"}
          mb={"20px"}
          ml={"10px"}
          mr={"20px"}
          float={"right"}
          isDisabled={isDesable}
          onClick={() => {
            // setfirst("growth percentage");
            // onOpen();
            navigate("/Capital-growth/createsharegrowth");
          }}
          // w={"30vh"}
          leftIcon={<AddIcon />}
          bg={"#B76A1D"}
          color={"white"}
          variant="solid"
        >
          Create Share growth
        </Button>
      </Box>
      <Center>
        <Capitalgrowthtable setisDesable={setisDesable}></Capitalgrowthtable>
      </Center>
    </Stack>
  );
}
