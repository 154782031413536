import { Box, Button, Progress, Text } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utilities/hooks/auth-hook";

export default function SatckholdersTable() {
  const { useState } = React;
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getStakeHolder = useQuery(
    "getStakeHolder",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getStakeHolder });
  const [columns, setColumns] = useState([
    {
      title: "#",
      render: (rowData) => {
        return <Text>{rowData.tableData.id + 1}</Text>;
      },
    },
    { title: "Name", field: "name" },
    { title: "ስም", field: "name_amharic" },

    // {
    //   title: "Phone Number ",
    //   field: "phone",
    // },
    {
      title: "Paid Up ",
      field: "shares",
      render: (rowData) => {
        return (
          <Text>{Number(rowData.current_capital).toLocaleString()} ETB</Text>
        );
      },
    },

    {
      title: "Number of share ",
      field: "no_of_shares",
    },
    {
      title: "Sold Shares ",
      field: "sold_shares_count",
      render: (rowData) => {
        return (
          <Text>{Number(rowData.sold_shares_count).toLocaleString()} ETB</Text>
        );
      },
    },
    {
      title: "Bought Shares ",
      field: "bought_shares_count",
      render: (rowData) => {
        return (
          <Text>
            {Number(rowData.bought_shares_count).toLocaleString()} ETB
          </Text>
        );
      },
    },
  ]);

  // const [data, setData] = useState([
  //   {
  //     grand: "test",
  //     code: 11111,
  //     color: "red",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 2222,
  //     color: "green",
  //     price: "100",
  //     quantity: "104,455,005",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 33333,
  //     color: "blue",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  // ]);
  return (
    <Box w={"100%"}>
      {/* {getStakeHolder.isFetching ? (
        <Loader />
      ) : ( */}
      <MaterialTable
        isLoading={getStakeHolder.isLoading}
        title="Shareholders List"
        columns={columns}
        data={data}
        options={{
          pageSize: 15,
          pageSizeOptions: [15, 20, 50, 100],
          filtering: true,

          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
        }}
        actions={[
          {
            icon: "save",
            tooltip: "Save User",
            onClick: (event, rowData) => alert("You saved " + rowData.name),
          },
        ]}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
          Action: (props) => (
            <>
              <Button
                onClick={() =>
                  navigate(`/Stake-holder/detail/${props?.data?.id}`, {
                    // state: {
                    //   id: props.data.id,
                    // },
                  })
                }
                bg={"#B76A1D"}
                color={"white"}
              >
                Detail
              </Button>
              <Button
                onClick={() =>
                  navigate(`/Stake-holder/update/${props?.data?.id}`, {
                    // state: {
                    //   id: props.data.id,
                    // },
                  })
                }
                bg={"#B76A1D"}
                color={"white"}
                mx={3}
              >
                Edit
              </Button>
            </>
          ),
        }}
      />
      {/* )} */}
    </Box>
  );
}
