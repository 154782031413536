import { Avatar, Box, HStack, Stack, Text } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import moment from "moment";
import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../assets/Loader";
import { useAuth } from "../../utilities/hooks/auth-hook";
import DetailTable from "./Table/DetailTable";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
const { useState } = React;

export default function Shareholder_detail() {
  const [selectedOption, setselectedOption] = React.useState([]);

  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };

  const { token } = useAuth();
  const [data4, setData4] = useState([]);
  const [Alldata, setAllData] = useState([]);

  const { shareholderId: id } = useParams();

  const [activity, setColumns] = useState([
    {
      title: "Date ",
      field: "created_at",
    },

    {
      title: "Event ",
      field: "event",
    },

    {
      title: "Amount ",
      field: "amount",
      // format amount column
      render: (rowData) => (
        <div>
          {Number(rowData.amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
          ETB
        </div>
      ),
    },
  ]);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  console.log(id);
  const getStakeHolderDetail = useQuery(
    "getStakeHolderDetail",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}stakeholder-detail/${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res?.data, "testing");
        setAllData(res?.data);
        setData4(res?.data?.activities);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log(Alldata, "all data");
  return (
    <Box>
      {getStakeHolderDetail.isFetching ? (
        <Loader />
      ) : (
        <>
          <Avatar size="lg" icon={<FaUserAlt fontSize="2rem" />} my={2}>
            {/* NIB */}
          </Avatar>
          <Stack padding={8} bg={"white"}>
            <HStack>
              {/* <Image
          borderRadius="full"
          boxSize="120px"
          src="https://bit.ly/dan-abramov"
          alt="Dan Abramov"
        /> */}
              <HStack
                align={"start"}
                justify={"space-evenly"}
                spacing={"40px"}
                mx={"auto"}
                width={"100%"}
              >
                <Box textAlign={"left"} color={"#AC7729"}>
                  <Text>Name: {Alldata?.name} </Text>
                  <Text>Phone Number: {Alldata?.phone}</Text>
                  <Text>Email : {Alldata?.email}</Text>
                  <Text>Address : {Alldata?.information?.address}</Text>
                  <Text>
                    Identification No.: {Alldata?.information?.id_number}
                  </Text>
                </Box>
                <Box textAlign={"left"} color={"#AC7729"} justify={"left"}>
                  <Text>
                    Total Capital:{" "}
                    {Number(Alldata?.current_capital).toLocaleString("en-US")}{" "}
                    ETB
                  </Text>
                  <Text>Bought Shares : {Alldata?.bought_shares_count}</Text>
                  <Text>Sold Shares : {Alldata?.sold_shares_count}</Text>
                  <Text>Total Share : {Alldata?.total_share}</Text>
                </Box>
                <Box textAlign={"left"} color={"#AC7729"} justify={"left"}>
                  <Text>Payable : {Alldata?.bought_shares_count}</Text>
                  <Text>Individual Average : {Alldata?.sold_shares_count}</Text>
                  <Text>
                    Dividend :{" "}
                    {Number(Alldata?.dividend).toLocaleString("en-US")} ETB
                  </Text>
                </Box>
              </HStack>
            </HStack>
            <br />
            <br />
            <DetailTable
              columns={activity}
              data={data4}
              title={"Activities  "}
            />

            {/* Transfer table */}
            <MaterialTable
              title={"Transfer"}
              columns={[
                {
                  title: "Date",
                  field: "transfer_date",
                },
                {
                  title: "Transferer",
                  field: "from.name",
                },
                {
                  title: "Transferer Capital",
                  field: "from.current_capital",
                  render: (data) => {
                    return (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {data?.type === "Sold" ? (
                            <ArrowDownwardIcon
                              fontSize="medium"
                              sx={{ color: "red", margin: "0 5px" }}
                            />
                          ) : (
                            <ArrowUpwardIcon
                              fontSize="medium"
                              style={{ color: "green" }}
                            />
                          )}

                          {`
                      ${Number(data?.from?.current_capital).toLocaleString(
                        "en-GB",
                        {
                          maximumFractionDigits: 2,
                        }
                      )} ETB`}
                        </Box>
                      </>
                    );
                  },
                },
                {
                  title: "Transferred Amount(Share)",
                  field: "money_amount",
                  render: (data) => {
                    return `${data?.type === "Sold" ? "-" : "+"} ${Number(
                      data.money_amount
                    ).toLocaleString("en-GB", {
                      maximumFractionDigits: 2,
                    })} ETB (${
                      (data?.type === "Sold" ? "-" : "+") +
                      Number(data.money_amount) / 500
                    } Shares)`;
                  },
                },

                {
                  title: "Transferi",
                  field: "to.name",
                  render: (data) => {
                    return (
                      <>
                        {data?.is_internal == 1
                          ? data?.to?.name
                          : data?.reference_number}{" "}
                      </>
                    );
                  },
                },

                {
                  title: "Transferi Capital",
                  field: "to.current_capital",
                  render: (data) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {data?.type === "Sold" ? (
                          <ArrowUpwardIcon
                            fontSize="medium"
                            style={{ color: "green" }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            fontSize="medium"
                            sx={{ color: "red", margin: "0 5px" }}
                          />
                        )}
                        <>
                          {` ${Number(
                            data?.to?.current_capital
                          )?.toLocaleString("en-GB", {
                            maximumFractionDigits: 2,
                          })} ETB`}
                        </>
                      </Box>
                    );
                  },
                },
                {
                  title: "Type (Respective to 'From Shareholder')",
                  field: "type",
                },
              ]}
              data={getStakeHolderDetail?.data?.transfers}
              options={{
                filtering: true,

                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#E82481",
                  color: "#FFF",
                },
              }}
            />
            {/* Share table */}
            <MaterialTable
              title={"Shares"}
              columns={[
                {
                  title: "Date",
                  field: "plowback_created_date",
                  render: (rowData) =>
                    moment(rowData.plowback_created_date).format("DD/MM/YYYY"),
                },
                {
                  title: "Share Amount",
                  field: "share_amount",
                },
                {
                  title: "Capital",
                  field: "current_capital",
                  render: (data) => {
                    return ` ${Number(data?.current_capital).toLocaleString(
                      "en-GB",
                      {
                        maximumFractionDigits: 2,
                      }
                    )} ETB`;
                  },
                },
                {
                  title: "Type",
                  field: "type",
                },
              ]}
              data={getStakeHolderDetail?.data?.data?.shares}
              options={{
                filtering: true,

                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#E82481",
                  color: "#FFF",
                },
              }}
            />
            {/* Dividend table */}
            <MaterialTable
              title={"Dividends"}
              columns={[
                {
                  title: "Start Date",
                  render: (data) => {
                    return moment(data?.profit?.start_date).format(
                      "DD/MM/YYYY"
                    );
                  },
                },
                {
                  title: "End Date",
                  render: (data) => {
                    return moment(data?.profit?.end_date).format("DD/MM/YYYY");
                  },
                },
                {
                  title: "Total Profit",
                  field: "money_amount",
                  render: (data) => {
                    return ` ${Number(
                      data?.profit?.money_amount
                    ).toLocaleString("en-GB", {
                      maximumFractionDigits: 2,
                    })} ETB`;
                  },
                },
                // {
                //   title: "Remaining Amount",
                //   field: "remaining_amount",
                //   render: (data) => {
                //     return ` ${Number(
                //       data?.profit?.remaining_amount
                //     ).toLocaleString("en-GB", {
                //       maximumFractionDigits: 2,
                //     })} ETB`;
                //   },
                // },
                {
                  title: "Dividend",
                  field: "total_payable",
                  render: (data) => {
                    return ` ${Number(data?.total_payable).toLocaleString(
                      "en-GB",
                      {
                        maximumFractionDigits: 2,
                      }
                    )} ETB`;
                  },
                },
              ]}
              data={getStakeHolderDetail?.data?.data?.dividends}
              options={{
                filtering: true,

                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#E82481",
                  color: "#FFF",
                },
              }}
            />
            {/* Payment table */}
            <MaterialTable
              title={"Payments"}
              columns={[
                {
                  title: "Start Date",
                  render: (data) => {
                    return moment(data?.profit?.start_date).format(
                      "DD/MM/YYYY"
                    );
                  },
                },
                // {
                //   title: "End Date",
                //   render: (data) => {
                //     return moment(data?.profit?.end_date).format("DD/MM/YYYY");
                //   },
                // },
                {
                  title: "Amount",
                  field: "money_amount",
                  render: (data) => {
                    return ` ${Number(
                      data?.profit?.money_amount
                    ).toLocaleString("en-GB", {
                      maximumFractionDigits: 2,
                    })} ETB`;
                  },
                },
                // {
                //   title: "Remaining Amount",
                //   field: "remaining_amount",
                //   render: (data) => {
                //     return ` ${Number(
                //       data?.profit?.remaining_amount
                //     ).toLocaleString("en-GB", {
                //       maximumFractionDigits: 2,
                //     })} ETB`;
                //   },
                // },
                {
                  title: "Dividend",
                  field: "total_payable",
                  render: (data) => {
                    return ` ${Number(data?.total_payable).toLocaleString(
                      "en-GB",
                      {
                        maximumFractionDigits: 2,
                      }
                    )} ETB`;
                  },
                },
              ]}
              data={getStakeHolderDetail?.data?.data?.data?.payments}
              options={{
                filtering: true,

                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#E82481",
                  color: "#FFF",
                },
              }}
            />
            {/* Capital growth table */}
            {/* <MaterialTable
              title={"Capital Growth"}
              columns={[
                {
                  title: "Start Date",
                  render: (data) => {
                    return moment(data?.profit?.start_date).format(
                      "DD/MM/YYYY"
                    );
                  },
                },
                {
                  title: "End Date",
                  render: (data) => {
                    return moment(data?.profit?.end_date).format("DD/MM/YYYY");
                  },
                },
                {
                  title: "Growth Percent",
                  field: "money_amount",
                  render: (data) => {
                    return <div>{data?.profit?.growth_percent * 100}%</div>;
                  },
                },
                {
                  title: "Growth Amount",
                  field: "share_growth",
                  render: (data) => {
                    return <div>{data?.profit?.share_growth * 100}%</div>;
                  },
                },

                {
                  title: "Dividend",
                  field: "total_payable",
                  render: (data) => {
                    return ` ${Number(data?.total_payable).toLocaleString(
                      "en-GB",
                      {
                        maximumFractionDigits: 2,
                      }
                    )} ETB`;
                  },
                },
              ]}
              data={getStakeHolderDetail?.data?.data?.data?.payments}
              options={{
                filtering: true,

                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#E82481",
                  color: "#FFF",
                },
              }}
            /> */}
          </Stack>
        </>
      )}
    </Box>
  );
}
