import {
  Box,
  Button,
  Center,
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Wrap,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import * as Yup from "yup";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import CustomInputField from "../../../utilities/components/CustomInputField";
import { CustomInput } from "../../../utilities/components/TaxUpdate";

const EditUserModal = ({
  isOpen,
  onClose,
  setUsersQueryReload,
  count,
  setCount,
  editId,
}) => {
  const { token, logout } = useAuth();

  const toast = useToast();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const navigate = useNavigate();

  const genderChoice = [
    { name: "Male", id: "Male" },
    { name: "Female", id: "Female" },
  ];

  const editUser = useMutation(
    async (editedUser) =>
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + `users/${editId}`,
        editedUser,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const userData = useQuery(
    `userData${editId}`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}users/${editId}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token && !!editId,
    }
  );

  const initialValues = {
    name: userData?.data?.data?.data?.name,
    phone: userData?.data?.data?.data?.phone,
    email: userData?.data?.data?.data?.email,
    password: "",
    confirmPassword: "",
    role: userData?.data?.data?.data?.role,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    role: Yup.object().required("Required"),
    phone: Yup.string()
      .min(9, "Too Short!")
      .max(12, "Too Long!")
      .required("Required"),
    password: Yup.string()
      .min(8, "Too Short!")
      .max(35, "Too Long!")
      .required("Required"),
    confirmPassword: Yup.string()
      .required("Confirm password is a required field")
      .label("Confirm password")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  const onSubmit = async (values) => {
    const { name, email, role, phone, password } = values;

    setCount(count + 1);
    try {
      editUser.mutate(
        {
          name,
          email: email,
          phone: `251${phone}`,
          password,
          role_id: role?.name,
        },
        {
          onSuccess: (responseData) => {
            onClose();
            setUsersQueryReload(true);
            toast({
              title: "User updated successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setUsersQueryReload(false);
          },
          onError: (responseDataError) => {
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                "User not updated",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
      // Redirect the admin to a different page
    } catch (err) {}
  };
  const roleOptions = [
    { name: "ADMIN", id: 1 },
    { name: "USER", id: 2 },
  ];
  console.log(userData?.data?.data?.data?.name, "user");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        {!editUser?.isFetching && !userData?.isFetching ? (
          <Center p={0}>
            {userData?.isSuccess ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(values) => {
                  return (
                    <Form>
                      <Box my={4}>
                        <Center
                          color="#AFAFAF"
                          fontSize="25px"
                          fontWeight="semibold"
                        >
                          Update Users
                        </Center>
                        <Divider color="#333" my={4} />
                      </Box>
                      <SimpleGrid
                        column={2}
                        fontSize="18px"
                        // pl={{ base: "0px", md: "10%" }}
                        spacing={4}
                      >
                        <Field name="name">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              title="Name"
                              htmlFor="name"
                              error={values.errors.name}
                              touched={values.touched.name}
                              myDefault={userData?.data?.data?.data?.name}
                            />
                          )}
                        </Field>

                        <Field name="email">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              title={"Email"}
                              htmlFor="email"
                              error={values.errors.email}
                              touched={values.touched.email}
                              myDefault={userData?.data?.data?.data?.email}
                            />
                          )}
                        </Field>
                        <Field name="phone">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              title={"Phone Number"}
                              htmlFor="phone"
                              error={values.errors.phone}
                              touched={values.touched.phone}
                              extra="phone"
                              myDefault={userData?.data?.data?.data?.phone}
                            />
                          )}
                        </Field>

                        <Field name="role">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              title={"Role"}
                              htmlFor="role"
                              error={values.errors.role}
                              touched={values.touched.role}
                              options={roleOptions}
                              myDefault={
                                userData?.data?.data?.data?.role?.name ===
                                "ADMIN"
                                  ? roleOptions[0]
                                  : roleOptions[1]
                              }
                              extra="select"
                            />
                          )}
                        </Field>

                        <Field name="password">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              title="Password"
                              htmlFor="password"
                              error={values.errors.password}
                              touched={values.touched.password}
                              extra="password"
                              type={"password"}
                            />
                          )}
                        </Field>
                        <Field name="confirmPassword">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              title="Confirm Password"
                              htmlFor="confirmPassword"
                              error={values.errors.confirmPassword}
                              touched={values.touched.confirmPassword}
                              extra="password"
                              type={"password"}
                            />
                          )}
                        </Field>
                      </SimpleGrid>
                      <Center>
                        <Button
                          my={5}
                          colorScheme="facebook"
                          disabled={!(values.isValid && values.dirty)}
                          _hover={
                            values.isValid &&
                            values.dirty && {
                              opacity: "0.8",
                            }
                          }
                          type={"submit"}
                          isLoading={editUser?.isLoading}
                        >
                          Add User
                        </Button>
                      </Center>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Center>Check your connection and refresh </Center>
            )}
          </Center>
        ) : (
          <Center alignSelf={"center"} minH="500px">
            <SyncLoader size={15} margin={2} color="#4962f2" />
          </Center>
        )}
      </ModalContent>
    </Modal>
  );
};

export default EditUserModal;
