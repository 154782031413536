import { Box, Button, Center, Stack } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import TransfersTable from "./Table/TransfersTable";
export default function Transfers() {
  let navigate = useNavigate();
  return (
    <Stack padding={10}>
      <Box>
        <Button
          mt={"20px"}
          mb={"20px"}
          ml={"10px"}
          mr={"20px"}
          float={"right"}
          onClick={() => {
            navigate("/transfers/CreateTransfers", {
              state: {
                state: null,
                isReadOnly: false,
                isEdit: false,
                isShow: false,
              },
            });
          }}
          // w={"30vh"}
          leftIcon={<AddIcon />}
          bg={"#B76A1D"}
          color={"white"}
          variant="solid"
        >
          Create Transfer
        </Button>
      </Box>
      <Center>
        <TransfersTable></TransfersTable>
      </Center>
    </Stack>
  );
}
