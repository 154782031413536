import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../utilities/hooks/auth-hook";
// import AddMeetingType from "./AddMeet.
import { Typography } from "@material-ui/core";
import TaxUpdate from "../../../../utilities/components/TaxUpdate";

export const TaxInfo = () => {
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editId, setEditId] = useState(false);
  const toast = useToast();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const deleteTyreCondition = useMutation(
    async (deleteServiceType) =>
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + `tyres/${deleteServiceType?.id}`,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const getProxy = useQuery(
    ["delegationdataforproxy"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}proxies`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );

  React.useEffect(() => {
    if (getProxy?.data?.data) {
      setData(getProxy?.data?.data?.data);
    }
  }, [getProxy?.data?.data]);
  const queryClient = useQueryClient();
  // const [columns, setColumns] = React.useState([
  //   { title: "Name", field: "name" },
  //   {
  //     title: "Attendee Amount",
  //     field: "attendee_amount",
  //   },
  //   {
  //     title: "Description",
  //     field: "description",
  //   },

  //   {
  //     render: (rowData) => {
  //       return (
  //         <HStack>
  //           <Button
  //             // disabled={tyreConditions?.isFetching ? true : false}
  //             size={"sm"}
  //             onClick={() => {
  //               setEditId(rowData?.id);
  //             }}
  //           >
  //             <AiFillEdit />
  //           </Button>
  //           <Box>
  //             <Button
  //               // disabled={tyreConditions?.isFetching ? true : false}
  //               size={"sm"}
  //               border="1px solid #006ddb"
  //               onClick={() => {
  //                 deleteTyreCondition.mutate(
  //                   {
  //                     id: rowData?.id,
  //                   },
  //                   {
  //                     onSuccess: (responseData) => {
  //                       toast({
  //                         title: " deleted successfully",
  //                         status: "success",
  //                         duration: 5000,
  //                         isClosable: true,
  //                       });
  //                       queryClient.invalidateQueries("tyres");
  //                     },
  //                     onError: (responseData) => {
  //                       toast({
  //                         title:
  //                           responseData?.response?.data?.message ||
  //                           "Not  deleted!",
  //                         status: "error",
  //                         duration: 5000,
  //                         isClosable: true,
  //                       });
  //                     },
  //                   }
  //                 );
  //               }}
  //             >
  //               <AiOutlineDelete />
  //             </Button>
  //           </Box>
  //           {deleteTyreCondition?.isLoading && <Loader />}
  //         </HStack>
  //       );
  //     },
  //   },
  // ]);
  const onCancel = () => {
    setEditId(false);
    onClose();
  };
  return (
    <>
      {/* <AddMeetingType isOpen={isOpen} onClose={onCancel} />
      <EditMeetingType editId={editId} isOpen={!!editId} onClose={onCancel} /> */}
      <TaxUpdate isOpen={isOpen} onClose={onCancel} />
      <Box w={"100%"} height={"100%"} align={"center"} sx={{}}>
        <Button my={2} onClick={onOpen}>
          Update Tax
        </Button>
        <Box
          sx={{
            shadow: "md",
            width: "20%",
            align: "center ",
            jutify: "center",
            display: "flex",
            my: 10,
            p: 5,
            borderRadius: 10,
            boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.75)",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
              ml: 2,
            }}
          >
            Current Tax is : 10%
          </Typography>
        </Box>

        {/* <MaterialTable
          isLoading={getProxy.isLoading}
          components={{
            OverlayLoading: (props) => (
              <Progress size="md" colorScheme="pink" isIndeterminate />
            ),
          }}
          title="Meeting Type"
          columns={columns}
          data={data}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#E82481",
              color: "#FFF",
            },
          }}
        /> */}
      </Box>
    </>
  );
};
