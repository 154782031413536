import { AiFillExclamationCircle } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { BsFillCapslockFill } from "react-icons/bs";
import { FaHistory } from "react-icons/fa";
import { FiUserPlus } from "react-icons/fi";
import { GiReceiveMoney } from "react-icons/gi";
import { MdDashboard } from "react-icons/md";
import { RiGitPullRequestLine } from "react-icons/ri";
import { TbReportMoney } from "react-icons/tb";

export const StoreManagerRoutes = [
  { name: "Dashboard", to: "/dashboard", icon: <MdDashboard size={"1.7em"} /> },

  {
    name: "Shareholder",
    to: "/Stake-holder",
    icon: <FiUserPlus size={"1.7em"} />,
  },

  {
    name: "Dividend, Average & Profit",
    to: "/dividend",
    icon: <TbReportMoney size={"1.7em"} />,
  },
  {
    name: "Share Request",
    to: "/Share_request",
    icon: <RiGitPullRequestLine size={"1.7em"} />,
  },
  {
    name: "Capital Growth",
    to: "/Capital-growth",
    icon: <BsFillCapslockFill size={"1.7em"} />,
  },
  {
    name: "Transfers ",
    to: "/transfers",
    icon: <BiTransfer size={"1.7em"} />,
  },
  {
    name: "Payments",
    to: "/payments",
    icon: <GiReceiveMoney size={"1.7em"} />,
  },
  {
    name: "User Management",
    to: "/users",
    icon: <FiUserPlus size={"1.7em"} />,
  },

  // {
  //   name: "Profit",
  //   to: "/profit",
  //   icon: <GiProfit size={"1.7em"} />,
  // },
  {
    name: "Report ",
    to: "/report",
    icon: <AiFillExclamationCircle size={"1.7em"} />,
  },
  {
    name: "Upload History  ",
    to: "/history",
    icon: <FaHistory size={"1.7em"} />,
  },
];
