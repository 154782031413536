import { Box, Center, Heading, Stack, Text, Wrap } from "@chakra-ui/react";
import React from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
} from "recharts";

const OutdoorSales = () => {
  const inpersonVsOnline = [
    { name: "Self PickUp", value: 25 },
    { name: "Express", value: 35 },
    { name: "Long Distance", value: 40 },
  ];

  const data = [
    {
      name: "2016",
      Dividend: 48000,
      amt: 218100,
    },
    {
      name: "2017",
      Dividend: 240000,
      amt: 240000,
    },
    {
      name: "2018",
      Dividend: 139800,
      amt: 221000,
    },
    {
      name: "2019",
      Dividend: 80000,
      amt: 229000,
    },
    {
      name: "2020",
      Dividend: 390800,
      amt: 200000,
    },
    {
      name: "2021",
      Dividend: 48000,
      amt: 218100,
    },
    {
      name: "2022",
      Dividend: 48000,
      amt: 218100,
    },
  ];

  const COLORS1 = ["#0088FE", "#00C49F"];
  const COLORS2 = ["#0088FE", "#00C49F"];
  const COLORS3 = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const renderCustomizedLabel1 = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={"12px"}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Stack spacing={"18px"}>
      {/* <Heading pl="5%"> Revenue Composition </Heading> */}
      <Wrap justify={"center"} align="center" width="100%" height="100%">
        <Stack>
          <Center>Annual Dividend</Center>
          <Box width="100%" height="100%">
            <BarChart
              width={500}
              height={350}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Dividend" fill="#B51D64" minPointSize={5} />
            </BarChart>
          </Box>
        </Stack>
        {/* <Stack>
          <Center>Order Type</Center>
          <PieChart width={320} height={300}>
            <Pie
              // isAnimationActive={false}
              data={inpersonVsOnline}
              cx="50%"
              cy="30%"
              label={renderCustomizedLabel1}
              outerRadius={80}
              fill="#FAD03C"
            />
            {inpersonVsOnline.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={"#B51D64"} />
            ))}
            <Tooltip />
          </PieChart>
        </Stack> */}
      </Wrap>
    </Stack>
  );
};

export default OutdoorSales;
