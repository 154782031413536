import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Wrap,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { SyncLoader } from "react-spinners";
import * as Yup from "yup";
import { CustomInput } from "../../../../utilities/components/TaxUpdate";
import { useAuth } from "../../../../utilities/hooks/auth-hook";
const AddMeetingType = ({ isOpen, onClose, editId }) => {
  const { token, logout } = useAuth();

  const toast = useToast();
  const queryclient = useQueryClient();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const addMeetingtype = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}meeting-types`,
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {
    name: "",
    minimum_attendant: "",
    description: "",
    start_from_minimum_attendant: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(" name  is required"),
    name_amharic: Yup.string().required(" name  is required"),
    minimum_attendant: Yup.number().required("its required").min(0).max(1),
    description: Yup.string(),
    description_amharic: Yup.string(),

    start_from_minimum_attendant: Yup.boolean(),
  });

  const onSubmit = async (values) => {
    try {
      addMeetingtype.mutate(
        {
          name: values.name,
          name_amharic: values.name_amharic,
          minimum_attendant: values.minimum_attendant,
          description: values.description,
          description_amharic: values.description_amharic,
          start_from_minimum_attendant: values.start_from_minimum_attendant,
        },
        {
          onSuccess: (responseData) => {
            onClose();
            toast({
              title: "Created successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            queryclient.invalidateQueries("MeetingType");
          },
          onError: (responseDataError) => {
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                " not created",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        <Center p={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(values) => {
              console.log(values);
              return (
                <Form>
                  <Box my={4}>
                    <Center
                      color="#AFAFAF"
                      fontSize="25px"
                      fontWeight="semibold"
                    >
                      Add Meeting Type
                    </Center>
                    <Divider color="#333" my={4} />
                  </Box>
                  <Wrap
                    fontSize="18px"
                    justify="center"
                    spacing={4}
                    direction={"column"}
                  >
                    <Field name="name">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title="name"
                          htmlFor="name"
                          error={values.errors.name}
                          touched={values.touched.name}
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Wrap
                    fontSize="18px"
                    justify="center"
                    spacing={4}
                    direction={"column"}
                  >
                    <Field name="name_amharic">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title="ስም"
                          htmlFor="name_amharic"
                          error={values.errors.name_amharic}
                          touched={values.touched.name_amharic}
                        />
                      )}
                    </Field>
                  </Wrap>

                  <Wrap justify="center" spacing={4} direction={"column"}>
                    <Field name="minimum_attendant">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title=" Minimum Attendant (0-1)"
                          htmlFor="minimum_attendant"
                          error={values.errors.minimum_attendant}
                          touched={values.touched.minimum_attendant}
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Wrap justify="center" spacing={4} direction={"column"}>
                    <Field name="description">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title="description"
                          htmlFor="description"
                          error={values.errors.description}
                          touched={values.touched.description}
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Wrap justify="center" spacing={4} direction={"column"}>
                    <Field name="description_amharic">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title="መግለጫ"
                          htmlFor="description_amharic"
                          error={values.errors.description_amharic}
                          touched={values.touched.description_amharic}
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Wrap justify="center" spacing={4} direction={"column"}>
                    {/* <Field name="start_from_minimum_attendant"> */}
                    <Checkbox
                      float={"left"}
                      id={"start_from_minimum_attendant"}
                      value={values.start_from_minimum_attendant}
                      colorScheme="green"
                      onChange={(value) => {
                        values.setFieldValue(
                          "start_from_minimum_attendant",
                          value.target.checked
                        );
                      }}
                    >
                      Can start
                    </Checkbox>
                    {/* </Field> */}
                  </Wrap>

                  <Center>
                    <Button
                      my={5}
                      colorScheme="facebook"
                      disabled={values.errors.name}
                      _hover={
                        values.isValid &&
                        values.dirty && {
                          opacity: "0.8",
                        }
                      }
                      type={!addMeetingtype?.isLoading ? "submit" : "button"}
                      isLoading={addMeetingtype?.isLoading}
                    >
                      Add
                    </Button>
                  </Center>
                </Form>
              );
            }}
          </Formik>
        </Center>
      </ModalContent>
    </Modal>
  );
};

export default AddMeetingType;
