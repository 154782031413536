import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

export default function SideButton({ children, to, name }) {
  //Hook
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  //Jsx
  return (
    <NavLink
      style={({ isActive }) => ({
        width: "100%",
        paddingTop: 7,
        paddingBottom: 7,
        paddingRight: 10,
        paddingLeft: 10,
        borderRadius: 7,
        boxShadow: isActive ? `2px 2px 2px -1px #B76A1D` : "",
        color: isActive ? "#fff" : "white",
        backgroundColor: isActive ? "#B76A1D" : "black",
      })}
      to={to}
    >
      <HStack alignItems={"center"}>
        {children}
        <Text w={"100%"} textAlign={"start"}>
          {name}
        </Text>
        {!!match ? <div>|</div> : ""}
      </HStack>
    </NavLink>
  );
}
