import {
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import React from "react";

import PasswordChangeModal from "./PasswordChangeModal";

const MainModal = ({ isOpen, passChange }) => {
  console.log({ isOpen });

  return (
    <Modal isOpen={isOpen} size="5xl">
      <ModalOverlay />

      <ModalContent>
        {/* <ModalCloseButton /> */}
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em"></TabList>
          <TabPanels>
            <TabPanel>
              <PasswordChangeModal loginData={isOpen} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalContent>
    </Modal>
  );
};

export default MainModal;
