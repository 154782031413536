import {
  Box,
  Button,
  Center,
  Divider,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Wrap,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";
import { CustomInput } from "../../../../utilities/components/TaxUpdate";
import { useAuth } from "../../../../utilities/hooks/auth-hook";
import { SyncLoader } from "react-spinners";
import CustomInputField from "../../../../utilities/components/CustomInputField";
const UpdateAgendaTemplate = ({ isOpen, onClose, editId }) => {
  const { token, logout } = useAuth();

  const toast = useToast();
  const queryclient = useQueryClient();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const agendaTemplate = useQuery(
    `meetingType${editId}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}agenda-templates/${editId}
      `,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token && !!editId,
    }
  );
  const addMeetingTemplate = useMutation(
    async (newData) =>
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}agenda-templates/${editId}`,
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {};
  if (agendaTemplate?.isSuccess) {
    initialValues.title = agendaTemplate?.data?.data?.data?.title;
    initialValues.description = agendaTemplate?.data?.data?.data?.description;
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("title is required"),
  });

  const onSubmit = async (values) => {
    try {
      addMeetingTemplate.mutate(
        {
          ...values,
        },
        {
          onSuccess: (responseData) => {
            onClose();
            toast({
              title: "Created successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            queryclient.invalidateQueries("agenda-template");
          },
          onError: (responseDataError) => {
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                " not created",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        {!agendaTemplate?.isFetching ? (
          <Center p={0}>
            {agendaTemplate?.isSuccess ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(values) => {
                  console.log(values);
                  return (
                    <Form>
                      <Box my={4}>
                        <Center
                          color="#AFAFAF"
                          fontSize="25px"
                          fontWeight="semibold"
                        >
                          Update Agenda Template{" "}
                          {agendaTemplate?.data?.data?.data?.title}
                        </Center>
                        <Divider color="#333" my={4} />
                      </Box>
                      <Wrap
                        fontSize="18px"
                        justify="center"
                        spacing={4}
                        direction={"column"}
                      >
                        <Field name="title">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              title="title"
                              htmlFor="title"
                              error={values.errors.title}
                              touched={values.touched.title}
                            />
                          )}
                        </Field>
                      </Wrap>
                      <Wrap justify="center" spacing={4} direction={"column"}>
                        <Field name="description">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              title="description"
                              htmlFor="description"
                              error={values.errors.description}
                              touched={values.touched.description}
                            />
                          )}
                        </Field>
                      </Wrap>
                      <Center>
                        <Button
                          my={5}
                          colorScheme="facebook"
                          disabled={
                            !values.isValid ||
                            !values.dirty ||
                            addMeetingTemplate?.isLoading
                          }
                          _hover={
                            values.isValid &&
                            values.dirty && {
                              opacity: "0.8",
                            }
                          }
                          type={"submit"}
                          isLoading={addMeetingTemplate?.isLoading}
                        >
                          Update
                        </Button>
                      </Center>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Center>Check your connection and refresh </Center>
            )}
          </Center>
        ) : (
          <Center alignSelf={"center"} minH="500px">
            <SyncLoader size={15} margin={2} color="#4962f2" />
          </Center>
        )}
      </ModalContent>
    </Modal>
  );
};
export default UpdateAgendaTemplate;
