import {
  Box,
  Button,
  Center,
  Checkbox,
  CircularProgress,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Progress,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import Select from "react-select";
import * as Yup from "yup";
import FormComp from "../../utilities/components/FormComp";

import axios from "axios";
import { Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import FormCompamount from "../../utilities/components/FormCompamount";
import { useAuth } from "../../utilities/hooks/auth-hook";
export default function Createshare() {
  const toSqlDatetime = (inputDate) => {
    var dd = String(inputDate.getDate()).padStart(2, "0");
    var mm = String(inputDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = inputDate.getFullYear();
    inputDate = yyyy + "-" + mm + "-" + dd;
    return inputDate;
  };
  const [profitedate, setprofitedate] = React.useState(new Date());
  console.log("full year ", profitedate.getFullYear());
  const [Frompayable, setFrompayable] = React.useState("");
  const [selectedOption, setselectedOption] = React.useState(null);
  const [selectedOption2, setselectedOption2] = React.useState([]);
  const [Alldata, setAlldata] = React.useState();
  const [options, setoptions] = React.useState([]);
  const [options2, setoptions2] = React.useState([]);
  const [Data, setData] = React.useState([]);
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [Message, setMessage] = React.useState("false");
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const handleChange2 = (selectedOption) => {
    setselectedOption2(selectedOption);
  };
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const shareMutation = useMutation(
    async (newData) =>
      await axios.post(process.env.REACT_APP_BACKEND_URL + "shares", newData, {
        headers,
      }),
    {
      retry: false,
    }
  );
  const ShareMutationHandler = async (values) => {
    const {
      additionAmount,
      Acrv,
      amount,
      plobackdate,
      cashoutdate,
      transferdate,
      shererequestdate,
      amb,
      frompayable,
      av,
      chashoutdate,
      receiver_name,
      prommised,
      transfer_amount,
      cashout_amount,
      acpv,
      share_request_amount,
    } = values;
    console.log({ selectedOption });
    try {
      shareMutation.mutate(
        {
          stakeholder_id: selectedOption?.id,
          plowback_created_date: plobackdate,
          additional_payment: additionAmount,
          acrv_no: Acrv,
          plowback_amount: amb,
          cashout_amount: cashout_amount,
          acpv_no: acpv,
          cashout_date: cashoutdate,
          transfer_amount: transfer_amount,
          transfer_to: selectedOption2?.id,
          transfer_date: transferdate,
          av_no: av,
          model: Data,
          budjet_year: profitedate.getFullYear(),
          from_payable: frompayable,
          request_share_amount: share_request_amount,
          request_share_date: shererequestdate,
          prommised: prommised,
        },

        {
          onSuccess: (responseData) => {
            toast({
              title: "Share created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/Stake-holder");
          },
          onError: (responseData) => {
            toast({
              title: `${responseData?.response?.data?.message}`,
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  console.log({ date: profitedate.getFullYear() - 9 });
  const getStakeHoldername = useQuery(
    "getStakeHoldername",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const getCaptitalG = useQuery(
    "getCaptitalG",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}capital-growths`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        var id = res.data.data[0].model;
        setData(id);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  // console.log(Data.length);
  // ?model=${Data}&budjetYear=${profitedate.getFullYear() - 9}
  const getInformation = useQuery(
    "getInformation" + selectedOption?.id,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}stakeholders/${selectedOption?.id}`,
        {
          headers,
          params: {
            budget_year: profitedate.getFullYear() - 1,
          },
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!selectedOption,
      onSuccess: (res) => {
        console.log(res.data.data);
        setAlldata(res.data.data);
        if (setoptions2.length !== 0) {
          setoptions2([]);
        }
        for (let i = 0; i < res.data.data.capital_growths.length; i++) {
          console.log(i);

          setoptions2((prevOpt) =>
            prevOpt.concat({
              title: `${res.data.data.capital_growths[i].start_date}/
              ${
                res.data.data.capital_growths[i].share_growth *
                res.data.data.stakeholder?.allowed_capital_growth
              } birr`,
              id: `${res.data.data.capital_growths[i].id}`,
            })
          );
        }
        // setoptions(res.data.data);
      },
      onError: (responseData) => {
        console.log(responseData.response.data.message);
        setMessage(responseData.response.data.message);
      },
    }
  );
  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"80%"}>
        <Heading mb={"10px"}> Activity</Heading>
        {getCaptitalG.isFetching ? (
          <Box>
            <CircularProgress isIndeterminate color="green" />
          </Box>
        ) : Data?.length === 0 ? (
          <>
            <Text fontWeight={"bold"} fontSize={22} color={"red"}>
              {" "}
              First Create Capital Growths
            </Text>
            <br />
            <Button
              onClick={() => {
                navigate("/Capital-growth/createsharegrowth");
              }}
            >
              Go to capital_growths
            </Button>
          </>
        ) : Message !== "false" ? (
          <Text fontWeight={"bold"} fontSize={22} color={"red"}>
            {Message}
          </Text>
        ) : (
          <Formik
            initialValues={{
              // nameid: "",

              additionAmount: "",
              Acrv: "",
              amount: 0,
              chashoutdate: "",
              plobackdate: "",
              cashoutdate: "",
              transferdate: "",
              shererequestdate: "",
              amb: 0,
              frompayable: "",
              av: "",
              receiver_name: "",
              transfer_amount: 0,
              cashout_amount: "",
              acpv: "",
              share_request_amount: "",
              prommised: false,
            }}
            validationSchema={Yup.object({
              // nameid: Yup.string().required("Required !"),
              additionAmount: Yup.number().max(Alldata?.total_cg),
              amount: Yup.number().max(Alldata?.current_dividend),
            })}
            onSubmit={(values, { setSubmitting }) => {
              ShareMutationHandler(values);
              console.log({ values });
              setSubmitting(false);
            }}
          >
            {(formik) => {
              console.log({ formik });
              return (
                <form onSubmit={formik.handleSubmit}>
                  <FormControl py={2} textAlign={"left"}>
                    <FormLabel>Select Stakeholder</FormLabel>
                    <Select
                      styles={{ textAlign: "left" }}
                      w={"20vh"}
                      // id={"nameid"}
                      placeholder="select"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={selectedOption}
                      onChange={(newSelection) => {
                        console.log(newSelection.id);
                        // formik?.setFieldValue("nameid", newSelection.id);
                        handleChange(newSelection);
                      }}
                      options={options}
                    />
                  </FormControl>
                  {!!selectedOption &&
                    (getInformation.isFetching ? (
                      <Box>
                        <CircularProgress isIndeterminate color="green" />
                      </Box>
                    ) : (
                      <FormControl py={2} textAlign={"left"}>
                        <Box paddingLeft={4} bg={"gray.100"} w={"100%"}>
                          <Center fontWeight={"bold"} fontSize={"18px"}>
                            Basic Information{" "}
                          </Center>
                          <Stack
                            direction={{ base: "column", md: "row" }}
                            justify={"space-between"}
                            w={"100%"}
                            px={"2%"}
                          >
                            <Box>
                              <Text fontWeight={"bold"}>
                                Total Capital:{" "}
                                {getInformation?.data?.data?.data?.capital}{" "}
                              </Text>
                              <Text fontWeight={"bold"}>
                                Dividend:{" "}
                                {
                                  getInformation?.data?.data?.data
                                    ?.current_dividend
                                }{" "}
                              </Text>
                              <Text fontWeight={"bold"}>
                                Payable:{" "}
                                {getInformation?.data?.data?.data?.payable}{" "}
                              </Text>
                              <Text fontWeight={"bold"}>
                                allowed capital growth: {Alldata?.total_cg}{" "}
                              </Text>
                            </Box>

                            <Box>
                              {getInformation?.data?.data?.data?.share_growth?.map(
                                (data, index) => {
                                  return (
                                    <HStack key={index}>
                                      <Text fontWeight={"bold"}>
                                        {" "}
                                        Year {index + 1}: {data.share_growth}
                                        birr
                                      </Text>
                                    </HStack>
                                  );
                                }
                              )}
                            </Box>
                          </Stack>
                        </Box>
                        <br />
                        <HStack>
                          <Text>Progress:</Text>{" "}
                          <Progress
                            colorScheme="green"
                            size="md"
                            w={"55%"}
                            bg={"gray.400"}
                            hasStripe
                            value={
                              formik.values.amount +
                                formik.values.additionAmount <=
                              Alldata?.total_cg
                                ? ((formik.values.amount +
                                    formik.values.additionAmount) *
                                    100) /
                                  Alldata?.total_cg
                                : 100
                            }
                          />
                        </HStack>
                        {formik.values.amount + formik.values.additionAmount >
                          Alldata?.total_cg && (
                          <Text fontSize={12} color={"red"}>
                            The entered amount most be less than or equal to{" "}
                            {Alldata?.total_cg}{" "}
                          </Text>
                        )}

                        <Tabs mt={2}>
                          <TabList>
                            <Tab>Plowback</Tab>
                            <Tab>Cashout</Tab>
                            <Tab>Transfer</Tab>
                            <Tab>Request Share</Tab>
                          </TabList>

                          <TabPanels>
                            <TabPanel>
                              <Box>
                                <Text textAlign={"left"}> Select Date</Text>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    clearable
                                    // views={["year"]}
                                    selectsRange={true}
                                    id={"plobackdate"}
                                    value={formik.values.plobackdate}
                                    placeholder="Date"
                                    onChange={(value) => {
                                      formik.setFieldValue(
                                        "plobackdate",
                                        toSqlDatetime(value)
                                      );
                                    }}
                                    // onChange={(date) => handleDateChange(date)}
                                    // minDate={new Date()}
                                    format="dd/MM/yyyy"
                                  />
                                </MuiPickersUtilsProvider>
                              </Box>
                              <HStack>
                                <FormCompamount
                                  id={"amount"}
                                  label={"Amount"}
                                  type="number"
                                  setFrompayable={setFrompayable}
                                  formik={formik}
                                />
                              </HStack>
                              <FormComp
                                id={"av"}
                                label={"AV number"}
                                type="name"
                                formik={formik}
                              />
                              <hr />
                              <Text>Additional Payment</Text>
                              <HStack w={"100%"}>
                                <Box w={"100%"}>
                                  <FormComp
                                    id={"additionAmount"}
                                    label={"Amount"}
                                    formik={formik}
                                    type={"number"}
                                  />
                                </Box>
                                <Box w={"100%"}>
                                  <FormComp
                                    id={"Acrv"}
                                    label={"ACRV"}
                                    formik={formik}
                                  />
                                </Box>
                              </HStack>
                            </TabPanel>
                            <TabPanel>
                              <Stack>
                                <Box>
                                  <Text textAlign={"left"}> Select Date</Text>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      clearable
                                      // views={["year"]}
                                      selectsRange={true}
                                      id={"chashoutdate"}
                                      value={formik.values.chashoutdate}
                                      placeholder=" Date"
                                      onChange={(value) => {
                                        formik.setFieldValue(
                                          "chashoutdate",
                                          toSqlDatetime(value)
                                        );
                                      }}
                                      // onChange={(date) => handleDateChange(date)}
                                      // minDate={new Date()}
                                      format="dd/MM/yyyy"
                                    />
                                  </MuiPickersUtilsProvider>
                                </Box>
                                <FormComp
                                  id={"cashout_amount"}
                                  label={"Enter Cashout Amount(birr)"}
                                  formik={formik}
                                />

                                <FormComp
                                  id={"acpv"}
                                  label={"Enter ACPV"}
                                  formik={formik}
                                />
                                <Checkbox
                                  id={"prommised"}
                                  value={formik.values.prommised}
                                  colorScheme="green"
                                  onChange={(value) => {
                                    formik.setFieldValue(
                                      "prommised",
                                      !formik.values.prommised
                                    );
                                  }}
                                >
                                  is prommised
                                </Checkbox>
                              </Stack>
                            </TabPanel>
                            <TabPanel>
                              <Stack>
                                <Box>
                                  <Text textAlign={"left"}> Select Date</Text>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      clearable
                                      // views={["year"]}
                                      selectsRange={true}
                                      id={"transferdate"}
                                      value={formik.values.transferdate}
                                      placeholder="Date"
                                      onChange={(value) => {
                                        formik.setFieldValue(
                                          "transferdate",
                                          toSqlDatetime(value)
                                        );
                                      }}
                                      // onChange={(date) => handleDateChange(date)}
                                      // minDate={new Date()}
                                      format="dd/MM/yyyy"
                                    />
                                  </MuiPickersUtilsProvider>
                                </Box>
                                <FormControl py={2} textAlign={"left"}>
                                  <FormLabel>
                                    Select receiver Stakeholder
                                  </FormLabel>
                                  <Select
                                    styles={{ textAlign: "left" }}
                                    w={"20vh"}
                                    id={"name"}
                                    // id={"nameid"}
                                    placeholder="select"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={selectedOption2}
                                    onChange={(newSelection) => {
                                      console.log(newSelection.id);
                                      // formik?.setFieldValue("nameid", newSelection.id);
                                      handleChange2(newSelection);
                                    }}
                                    options={options}
                                  />
                                </FormControl>
                                <FormComp
                                  id={"transfer_amount"}
                                  label={" Transfer Amount"}
                                  formik={formik}
                                />
                              </Stack>
                            </TabPanel>
                            <TabPanel>
                              <Stack>
                                <Box>
                                  <Text textAlign={"left"}> Select Date</Text>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      clearable
                                      // views={["year"]}
                                      selectsRange={true}
                                      id={"shererequestdate"}
                                      value={formik.values.shererequestdate}
                                      placeholder="Date"
                                      onChange={(value) => {
                                        formik.setFieldValue(
                                          "shererequestdate",
                                          toSqlDatetime(value)
                                        );
                                      }}
                                      // onChange={(date) => handleDateChange(date)}
                                      // minDate={new Date()}
                                      format="dd/MM/yyyy"
                                    />
                                  </MuiPickersUtilsProvider>
                                </Box>
                                <FormComp
                                  id={"share_request_amount"}
                                  label={"Enter Share Request Amount (Birr)"}
                                  formik={formik}
                                />
                              </Stack>
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </FormControl>
                    ))}

                  <Button
                    mt={6}
                    type="submit"
                    bg={"#B76A1D"}
                    color={"white"}
                    disabled={
                      formik.values.amount + formik.values.additionAmount >
                      Alldata?.total_cg
                        ? true
                        : false
                    }
                    variant="solid"
                  >
                    Submit
                  </Button>
                </form>
              );
            }}
          </Formik>
        )}
      </Box>
    </Stack>
  );
}
