import {
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useToast,
  Button,
  Input,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useMutation, useQuery } from "react-query";
import CrosssiteActivity from "./components/CrosssiteActivity";
// import DashboardMain from "./components/DashboardMain";
// import LineGraph from "./components/LineGraph";
import OutdoorSales from "./components/OutdoorSales";
import RevenueComposition from "./components/RevenueComposition";
import { useAuth } from "../../utilities/hooks/auth-hook";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function DashBoard() {
  //Hook
  const { token } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const dashboardData = useQuery(
    `dashboardCardData`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}dashboard`, {
        headers,
      }),
    {
      keepPreviousData: true,
      retry: true,
      enabled: !!token,
    }
  );

  // console.log({ dashboardData: dashboardData?.data?.data?.data?.overview });
  const fileHeaders = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const fileInputRef = React.useRef();

  //Function
  function Cards({ title, subTitle, body, color }) {
    return (
      <Stack
        justifyContent={"flex-start"}
        bg={"white"}
        color={"#31C736"}
        w={"250px"}
        h={"125px"}
        px={6}
        py={4}
        mr={4}
        boxShadow={"md"}
        rounded={"md"}
      >
        {/* <Heading size={'lg'} fontWeight={'semibold'}>
          {title}
        </Heading> */}
        {/* <HStack alignItems={'flex-end'}> */}
        <Heading size={"2xl"} color={color}>
          {title}
        </Heading>
        <dr></dr>
        <Heading size={"xs"} color={"black"}>
          {subTitle}
        </Heading>
        {/* </HStack> */}
      </Stack>
    );
  }
  const toast = useToast();

  const excelUpload = useMutation(
    async (newData) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "upload-excel",
        newData,
        {
          headers: fileHeaders,
        }
      ),
    {
      retry: false,
    }
  );

  const [excel_file, setExcelFile] = React.useState(null);
  const [year, setYear] = React.useState(null);
  const initialValues = {
    name: "",
    minimum_attendant: "",
    description: "",
    start_from_minimum_attendant: false,
  };
  let formData = new FormData();
  formData.append("excel_file", excel_file);
  formData.append("year_month", moment(year).format("YYYY-MM"));
  const onSubmit = async (values) => {
    try {
      excelUpload.mutate(formData, {
        onSuccess: (responseData) => {
          toast({
            title: "Created successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        },
        onError: (responseDataError) => {
          toast({
            title:
              responseDataError?.response?.data?.data ||
              responseDataError?.response?.data?.message ||
              " not created",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        },
      });
    } catch (err) {}
  };
  const handleFileChange = (e) => {
    setExcelFile(e.target.files[0]);
  };
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };
  console.log(year, excel_file);
  const navigate = useNavigate();

  return (
    <Stack padding={4}>
      {/* <SimpleGrid columns={3} spacing={8}> */}
      <Wrap justify={["center", "flex-start"]}>
        <WrapItem>
          <Cards
            title={dashboardData?.data?.data?.data?.stakeholders}
            subTitle="Total Shareholders"
            color={"#E82481"}
            // body={dashboardData?.data?.data?.data?.overview?.activeOrders}
          />
        </WrapItem>
        <WrapItem>
          <Cards
            title={dashboardData?.data?.data?.data?.totalRevenue}
            subTitle="Total Revenue This Year"
            color={"#18B933"}
            // body={dashboardData?.data?.data?.data?.overview?.todaySchedules}
          />
        </WrapItem>
        <WrapItem>
          <Cards
            title={dashboardData?.data?.data?.data?.totalDividend}
            subTitle="Total Dividend This Year"
            color={"#FAD03C"}
            // body={dashboardData?.data?.data?.data?.overview?.todaySold}
          />
        </WrapItem>
        {/* <WrapItem
          onClick={() => {
            console.log("Live report");
            navigate("/live-report");
          }}
        >
          <Cards
            title={"Live Report"}
            color={"#FAD03C"}
            // body={dashboardData?.data?.data?.data?.overview?.todaySold}
          />
        </WrapItem> */}
      </Wrap>

      {/* <Cards
          title="Currently"
          subTitle="No of items in critical level"
          // body={dashboardData?.data?.data?.data?.overview?.criticalItem}
        /> */}
      {/* <Cards
          title="Currently"
          subTitle="No of total customers"
          // body={dashboardData?.data?.data?.data?.overview?.totalCustomers}
        /> */}
      {/* <Cards
          title="Currently"
          subTitle="No. of Total Items in Stock"
          // body={dashboardData?.data?.data?.data?.overview?.totalStock}
        />
        <Cards
          title="Currently"
          subTitle="No. of Total Suppliers"
          // body={dashboardData?.data?.data?.data?.overview?.totalSuppliers}
        />
        <Cards
          title="Currently"
          subTitle="No. of Total Drivers"
          // body={dashboardData?.data?.data?.data?.overview?.totalDrivers}
        /> */}
      {/* </SimpleGrid> */}
      {/* <LineGraph /> */}
      <Stack
        pt={8}
        spacing={"35px"}
        // w={{ base: "71%", xl: "95%" }}
      >
        {/* <DashboardMain /> */}
        <CrosssiteActivity />
        <RevenueComposition />
        {/* <OutdoorSales /> */}
      </Stack>
      <Stack direction="column" align="center" border="1px" gap={10}>
        <input
          type="file"
          accept=".xlsx,.xls,.csv"
          onChange={(e) => handleFileChange(e)}
        />
        <input type="date" onChange={(e) => handleYearChange(e)} />
        <Button
          disabled={!excel_file || !year}
          sx={{
            bg: "red",
          }}
          onClick={() => onSubmit()}
        >
          Upload file
        </Button>
      </Stack>
    </Stack>
  );
}
