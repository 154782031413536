import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import CapitalGrowth from "../storemanager/Capital_Growth/CapitalGrowth";
import Createsharegrowth from "../storemanager/Capital_Growth/Createsharegrowth";
import Createdividend from "../storemanager/Dividend/Createdividend";
import Dividend from "../storemanager/Dividend/Dividend";
import Createpayments from "../storemanager/Payments/Createpayment";
import Payments from "../storemanager/Payments/Payments";
import Profit from "../storemanager/Profit/Profit";
import Report from "../storemanager/Report/Report";
import CreateStakeholder from "../storemanager/Shareholder/CreateStakeholder";
import Createshare from "../storemanager/Shareholder/Createshare";
import Shareholder from "../storemanager/Shareholder/Shareholder";
import Shareholder_detail from "../storemanager/Shareholder/Shareholder_detail";
import StoreManager from "../storemanager/StoreManager";
import CreateTransfers from "../storemanager/Transfers/CreateTransfers";
import Transfers from "../storemanager/Transfers/Transfers";
import DashBoard from "../storemanager/dashboard/Dashboard";
// import Createprofit from "../storemanager/Profit/C reateprofit";
import Login from "../Login/Login";
import Createprofit from "../storemanager/Dividend/Createprofit";
import Editepage from "../storemanager/Editepage/Editepage";
import Historypage from "../storemanager/History/Historypage";
import CreateSharerequest from "../storemanager/Request_share/Payments/CreateSharerequest";
import Sharerequest from "../storemanager/Request_share/Payments/Sharerequest";
import UpdateStakeholder from "../storemanager/Shareholder/UpdateShareholder";
import Error from "../storemanager/components/404";
import ManageAllUsers from "../storemanager/users/ManageAllUsers";
import UtilityManagement from "../storemanager/utility/UtilsManagement";

const StoreManagerRoutes = () => {
  return (
    <div>
      <div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/" element={<StoreManager />}>
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/Stake-holder" element={<Shareholder />} />
            <Route
              path="/Stake-holder/Createplowback"
              element={<Createshare />}
            />
            <Route
              path="/Stake-holder/update/:id"
              element={<UpdateStakeholder />}
            />
            <Route path="/dividend" element={<Dividend />} />
            <Route path="/dividend/createprofit" element={<Createprofit />} />
            <Route
              path="/dividend/createDividend"
              element={<Createdividend />}
            />
            <Route path="/transfers" element={<Transfers />} />x
            <Route path="/utils" element={<UtilityManagement />} />
            <Route path="/profit" element={<Profit />} />
            <Route path="/profit/createprofit" element={<Createprofit />} />
            <Route path="/Share_request" element={<Sharerequest />} />
            <Route path="/dividend/Edit" element={<Editepage />} />
            <Route
              path="/Share_request/Create_Share_request"
              element={<CreateSharerequest />}
            />
            <Route
              path="/transfers/CreateTransfers"
              element={<CreateTransfers />}
            />
            <Route path="/payments" element={<Payments />} />
            <Route
              path="/payments/Createpayments"
              element={<Createpayments />}
            />
            <Route
              path="/Stake-holder/CreateStakeholder"
              element={<CreateStakeholder />}
            />
            <Route
              path="/Stake-holder/detail/:shareholderId"
              element={<Shareholder_detail />}
            />
            <Route path="/Capital-growth" element={<CapitalGrowth />} />
            <Route
              path="/Capital-growth/createsharegrowth"
              element={<Createsharegrowth />}
            />
            <Route path="/report" element={<Report />} />
            <Route path="/history" element={<Historypage />} />
            <Route path="/users" element={<ManageAllUsers />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
      <div />
    </div>
  );
};

export default StoreManagerRoutes;
