import { Box } from "@chakra-ui/react";
import MaterialTable from "material-table";
import React from "react";

export default function DetailTable(props) {
  const { data, columns, title } = props;

  return (
    <Box>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        options={{
          filtering: true,

          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
        }}
      />
    </Box>
  );
}
