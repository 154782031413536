import { Box, Button } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Loader from "../../../assets/Loader";
import { useAuth } from "../../../utilities/hooks/auth-hook";

export default function Capitalgrowthtable(props) {
  const { setisDesable } = props;
  const { useState } = React;
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getCaptitalG = useQuery(
    "getCaptitalG",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}capital-growths`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data.length);
        setData(res.data.data);

        if (res.data.data.length > 0) {
          setisDesable(true);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getCaptitalG });
  const [columns, setColumns] = useState([
    { title: "Start Date", field: "start_date" },
    {
      title: "End Date ",
      field: "end_date",
    },
    {
      title: "Total percentage",
      field: "percentage",
    },
    {
      title: "Share growth percentage",
      field: "share_growth",
    },
  ]);

  // const [data, setData] = useState([
  //   {
  //     grand: "test",
  //     code: 11111,
  //     color: "red",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 2222,
  //     color: "green",
  //     price: "100",
  //     quantity: "104,455,005",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 33333,
  //     color: "blue",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  // ]);
  return (
    <Box w={"100%"}>
      {getCaptitalG.isFetching ? (
        <Loader />
      ) : (
        <MaterialTable
          title="share growth  List"
          columns={columns}
          data={data}
          options={{
            filtering: true,

            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#E82481",
              color: "#FFF",
            },
          }}
        />
      )}
    </Box>
  );
}
